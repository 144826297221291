import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { TextField } from 'assets/components/text-field';
import { useForm } from 'assets/form';
import { Platform, View } from 'react-native';
import theme, { getSpacing } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useResetPasswordState } from './reset-password-store';
import { ResetPasswordForm } from './reset-password-actions';
import { useNavigation } from '@react-navigation/native';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { resetPassword } from './reset-password-actions';
import { UpdateWeakPasswordTestIDs } from './ResetPasswordTestIDs';
import { PasswordRules } from '../../common/PasswordRules';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import * as Linking from 'expo-linking';
import { isPassword } from '@digitalpharmacist/validation-dp';

export const UpdateWeakPassword: FunctionComponent<
  PropsWithChildren<UpdateWeakPasswordProps>
> = (props) => {
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      password: '',
      passwordConfirm: '',
      email: '',
    },
  });

  const passwordValue = methods.watch('password');
  const { status, error, updateData, data } = useResetPasswordState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  if (Platform.OS === 'web') {
    Linking.getInitialURL().then((url) => {
      if (url) {
        const params = Linking.parse(url).queryParams;
        methods.setValue(
          'confirmationCode',
          params?.confirmationCode as string,
        );
        methods.setValue('email', params?.email as string);
      }
    });
  }
  const handleSubmit = async () => {
    updateData({ ...data, email: methods.getValues().email });
    resetPassword(methods.getValues(), navigation);
  };

  const handleGoToLogin = async () => {
    navigation.navigate('intro');
  };

  return (
    <ScreenContainer>
      <View>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 30,
            paddingTop: getSpacing(2),
            paddingBottom: getSpacing(1),
            textAlign: 'center',
          }}
        >
          {getText('create-new-password')}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[600],
            fontSize: 16,
            paddingBottom: getSpacing(2),
            textAlign: 'center',
          }}
        >
          {getText('update-weak-password')}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={UpdateWeakPasswordTestIDs.passwordInput}
              label={getText('new-password')}
              name="password"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return isPassword(methods.getValues().password)
                      ? true
                      : getText('password-entry-does-not-meet-criteria');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={UpdateWeakPasswordTestIDs.passwordConfirmInput}
              label={getText('confirm-new-password')}
              name="passwordConfirm"
              rules={{
                required: getText('password-is-required'),
                validate: {
                  value: () => {
                    return methods.getValues().password ===
                      methods.getValues().passwordConfirm
                      ? true
                      : getText('passwords-must-match');
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === 'loading'}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
        <Form.Actions>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === 'loading'}
              logger={{ id: 'update-weak-password-submit-button' }}
            >
              {getText('change-password')}
            </Button>
          </View>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={handleGoToLogin}
              hierarchy="tertiary"
              logger={{ id: 'update-weak-password-back-to-login-button' }}
            >
              {getText('go-to-login')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

interface UpdateWeakPasswordProps {}
