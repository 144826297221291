import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { FlatList, Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import { Button } from 'assets/components/button';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  MedicalInfoForm,
  refreshRecordUnderCareRequestsState,
  respondToRequestUnderCare,
} from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import { usePatientUnderCareState } from './patient-store';
import { getText } from 'assets/localization/localization';
import { ScreenContainer, Form } from 'assets/layout';
import { Icon } from 'assets/components/icon';
import { CalendarIcon } from 'assets/icons';
import { BottomSheetModal } from '@gorhom/bottom-sheet';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { Modal } from 'assets/components/modal';
import { useUserState } from '../../../store/user-store';
import {
  PatientRecordDto,
  RecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareRequestDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { useAppStateStore } from '../../../store/app-store';
import moment from 'moment';
import patientService from '../../../api/patient-service';
import { SafeAreaView } from 'react-native-safe-area-context';
import { RevokeAccessMobileComponent } from './components/revoke-access/RevokeAccessMobileComponent';
import { RevokeAccessWebComponent } from './components/revoke-access/RevokeAccessWebComponent';
import { formatDateToMMMD } from '../../../common/datetime-utils';
import { ScrollView } from 'react-native-gesture-handler';
import {
  BoolString,
  MedicalInfoFormFields,
} from '../../../components/medical-info/MedicalInfoFormFields';
import { LoadingIndicator } from 'assets/components/loading-indicator';

export const MedicalInfo: FunctionComponent<
  PropsWithChildren<MedicalInfoProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const { recordUnderCareRequests, caregivers } = usePatientUnderCareState();
  const isWeb = Platform.OS === 'web';
  const [isCaregiverApprovalOpen, setIsCaregiverApprovalOpen] = useState(false);
  const caregiverApprovalBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { pharmacyName } = useAppStateStore();
  const [isApproved, setIsApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const revokeAccessBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const user = useUserState.getState().user;
  const medicalForm = useForm<MedicalInfoForm>({});
  const [patientRecord, setPatientRecord] = useState<PatientRecordDto>();

  if (!user) {
    throw new Error('No user found');
  }

  const patientRecordId = user.patientRecordId;
  if (!patientRecordId)
    throw new Error('Logged in user has no patient record ID');

  const refreshPatientRecord = async () => {
    const pr = await patientService.findPatientRecord(patientRecordId);
    setPatientRecord(pr);

    medicalForm.setValue('allergies', pr.allergies);

    if (pr.prefers_easy_open_bottle_caps != null) {
      medicalForm.setValue(
        'prefers_easy_open_bottle_caps_str',
        pr.prefers_easy_open_bottle_caps ? BoolString.True : BoolString.False,
      );
    }
  };

  useEffect(() => {
    void refreshRecordUnderCareRequestsState();
    void refreshPatientRecord();
  }, [isApproved, showModal]);

  if (!patientRecord) {
    return <LoadingIndicator />;
  }

  const handleShowModal = (show: boolean) => {
    if (Platform.OS === 'web') {
      setShowModal(show);
    } else {
      if (!show) {
        revokeAccessBottomSheetRef.current?.dismiss();
      } else {
        revokeAccessBottomSheetRef.current?.present();
      }
    }
  };

  const handleOnPressRevoke = async (recordId: string, id: string) => {
    await patientService.revokeByPatientUnderCare(id, recordId);
    handleShowModal(false);
  };

  const openCaregiverApprovalAgreement = (show: boolean) => {
    setIsApproved(show);
    if (Platform.OS === 'web') {
      setIsCaregiverApprovalOpen(show);
    } else {
      if (!show) {
        caregiverApprovalBottomSheetRef.current?.dismiss();
      } else {
        caregiverApprovalBottomSheetRef.current?.present();
      }
    }
  };

  const respondToCaregiverAgreement = async (
    requestId: string,
    isApproved: boolean,
  ) => {
    const updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto = {
      status: isApproved
        ? RecordUnderCareRequestStatus.Approved
        : RecordUnderCareRequestStatus.Denied,
    };
    await respondToRequestUnderCare(requestId, updateRecordUnderCareRequestDto);
    openCaregiverApprovalAgreement(false);
  };

  const handleSubmit = () => {
    const { allergies, prefers_easy_open_bottle_caps_str } =
      medicalForm.getValues();

    const prefers_easy_open_bottle_caps =
      prefers_easy_open_bottle_caps_str === BoolString.True;

    updatePatientRecord({ allergies, prefers_easy_open_bottle_caps }).catch(
      (error) => {
        alert(error); //TODO Error handling
      },
    );
  };

  const updatePatientRecord = async (updatePR: UpdatePatientRecordDto) => {
    setPatientRecord(
      await patientService.updatePatientRecord(patientRecord.id, updatePR),
    );
  };

  return (
    <ScreenContainer>
      <Form methods={medicalForm}>
        <MedicalInfoFormFields />

        {/* TODO remove this temporary */}
        <Text>{JSON.stringify(medicalForm.getValues(), null, 2)}</Text>

        <Form.Actions>
          {/* This will be changed */}
          <Button
            onPress={medicalForm.handleSubmit(handleSubmit)}
            hierarchy="primary"
            logger={{ id: 'medical-info-submit-button' }}
          >
            {getText('submit')}
          </Button>
        </Form.Actions>
      </Form>

      {(recordUnderCareRequests.length > 0 || caregivers.length > 0) && (
        <View style={styles.lineStyle}>
          <Text style={styles.requestHeaderStyle}>
            {getText('authorized-caregivers').toUpperCase()}
          </Text>
        </View>
      )}
      {caregivers.map((record) => (
        <View style={styles.requestContainer} key={record.id}>
          <View style={styles.requestTextContainer}>
            <Text style={styles.nameText}>
              {`${record.caregiver_first_name} ${record.caregiver_last_name}`.trim()}{' '}
            </Text>
            <TouchableOpacity
              style={styles.textContainer}
              onPress={() => handleShowModal(true)}
            >
              <Text
                style={[
                  styles.pressableTextRevoke,
                  styles.leftSpacing,
                  isWeb && styles.pointer,
                ]}
              >
                {getText('revoke-access')}
              </Text>
            </TouchableOpacity>
          </View>
          {Platform.OS === 'web' ? (
            <RevokeAccessWebComponent
              handleCancelOnPress={handleShowModal}
              handleRevokeOnPress={handleOnPressRevoke}
              showModal={showModal}
              record={record}
            ></RevokeAccessWebComponent>
          ) : (
            <RevokeAccessMobileComponent
              revokeAccessBottomSheetRef={revokeAccessBottomSheetRef}
              dismissBottomSheet={handleShowModal}
              handleRevokeOnPress={handleOnPressRevoke}
              record={record}
            ></RevokeAccessMobileComponent>
          )}
          <View style={styles.rowDirection}>
            <Text style={styles.emailTextStyle}>{record.caregiver_email}</Text>
          </View>

          <View style={styles.authorizationContainer}>
            <View style={styles.rightSpacing}>
              <Icon
                icon={CalendarIcon}
                color={theme.palette.gray[500]}
                size={14}
              ></Icon>
            </View>
            <Text style={styles.requestAuthorizedStyle}>
              {getText('authorized-on', {
                authorizedDate: formatDateToMMMD(record.created_at),
              })}
            </Text>
          </View>
        </View>
      ))}
      {recordUnderCareRequests.map((request) => (
        <View key={request.id}>
          <View style={styles.requestContainer}>
            <View style={styles.requestTextContainer}>
              <Text style={styles.nameText}>
                {`${request.caregiver_first_name} ${request.caregiver_last_name}`.trim()}
              </Text>
              <TouchableOpacity
                onPress={() => openCaregiverApprovalAgreement(true)}
                style={styles.textContainer}
              >
                <Text
                  style={[
                    styles.pressableText,
                    styles.leftSpacing,
                    isWeb && styles.pointer,
                  ]}
                >
                  {getText('review-request')}
                </Text>
              </TouchableOpacity>
            </View>
            <View style={styles.rowDirection}>
              <Text style={styles.emailTextStyle}>
                {request.caregiver_email}
              </Text>
            </View>

            <View style={styles.authorizationContainer}>
              <View style={styles.rightSpacing}>
                <Icon
                  icon={CalendarIcon}
                  color={theme.palette.warning[500]}
                  size={14}
                ></Icon>
              </View>
              <Text style={styles.requestPendingStyle}>
                {getText('request-status', {
                  submittedDate: formatDateToMMMD(request.created_at),
                })}
              </Text>
            </View>
            {Platform.OS === 'web' ? (
              <WebComponent
                isCaregiverApprovalOpen={isCaregiverApprovalOpen}
                pharmacyName={pharmacyName}
                request={request}
                openCaregiverApprovalAgreement={() =>
                  openCaregiverApprovalAgreement(false)
                }
                respondToCaregiverAgreement={respondToCaregiverAgreement}
              ></WebComponent>
            ) : (
              <MobileComponent
                caregiverApprovalBottomSheetRef={
                  caregiverApprovalBottomSheetRef
                }
                request={request}
                openCaregiverApprovalAgreement={() =>
                  openCaregiverApprovalAgreement(false)
                }
                pharmacyName={pharmacyName}
                respondToCaregiverAgreement={respondToCaregiverAgreement}
              ></MobileComponent>
            )}
          </View>
        </View>
      ))}
    </ScreenContainer>
  );
};

const WebComponent: FunctionComponent<
  PropsWithChildren<{
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    isCaregiverApprovalOpen: boolean;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    respondToCaregiverAgreement: (
      requestId: string,
      isApproved: boolean,
    ) => void;
  }>
> = ({
  request,
  pharmacyName,
  isCaregiverApprovalOpen,
  openCaregiverApprovalAgreement,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Modal
      show={isCaregiverApprovalOpen}
      okButtonProps={{
        onPress: () => respondToCaregiverAgreement(request.id, true),
        logger: {
          id: 'caregiver-agreement-authorize-button-modal',
        },
        text: getText('authorize'),
      }}
      dismissButtonProps={{
        onPress: () => openCaregiverApprovalAgreement(false),
        logger: { id: 'caregiver-agreement-close-button-modal' },
      }}
      cancelButtonProps={{
        onPress: () => respondToCaregiverAgreement(request.id, false),
        logger: { id: 'caregiver-agreement-decline-button-modal' },
        text: getText('decline'),
        hierarchy: 'destructive-secondary',
      }}
      isScrollable
      height={800}
    >
      <Text style={styles.headerTitle}>
        {getText('caregiver-approval-agreement')}
      </Text>
      <View style={styles.contentAlignmentCenter}>
        <Text style={styles.modalText}>
          {getText('puc-permission-access-to-records', {
            firstName: request.caregiver_first_name,
            lastName: request.caregiver_last_name,
          })}
        </Text>
        <View style={styles.lineStyle}>
          <Text style={styles.listItemTitle}>
            {getText('if-you-authorize-person')}
          </Text>
        </View>
        <View style={styles.leftSpacing}>
          <FlatList
            data={[
              { key: getText('manage-prescriptions') },
              { key: getText('order-refills-prescriptions') },
              { key: getText('pick-up-prescriptions') },
              {
                key: getText('discuss-care', {
                  pharmacyName: pharmacyName,
                }),
              },
              {
                key: getText('manage-health'),
              },
            ]}
            renderItem={({ item }) => {
              return (
                <View style={{ marginBottom: 6 }}>
                  <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                </View>
              );
            }}
          />
        </View>
        <Text style={styles.listItemTitle}>{getText('access-validity')}</Text>
        <Text style={[styles.modalText, { marginTop: theme.getSpacing(1) }]}>
          {getText('revoke-access-in-settings')}
        </Text>
      </View>
    </Modal>
  );
};

const MobileComponent: FunctionComponent<
  PropsWithChildren<{
    caregiverApprovalBottomSheetRef: React.RefObject<BottomSheetModal>;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    respondToCaregiverAgreement: (
      requestId: string,
      isApproved: boolean,
    ) => void;
  }>
> = ({
  caregiverApprovalBottomSheetRef,
  openCaregiverApprovalAgreement,
  request,
  pharmacyName,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <BottomSheet
      bottomSheetRef={caregiverApprovalBottomSheetRef}
      height={'100%'}
      onDismiss={() => openCaregiverApprovalAgreement(false)}
      footerContent={
        <SafeAreaView style={styles.buttonContainer}>
          <Button
            hierarchy="destructive-secondary"
            logger={{
              id: 'decline-bottom-sheet-button',
            }}
            style={styles.declineButtonStyle}
            onPress={() => respondToCaregiverAgreement(request.id, false)}
          >
            {getText('decline')}
          </Button>
          <Button
            hierarchy="primary"
            logger={{
              id: 'authorize-bottom-sheet-button',
            }}
            style={{ width: '50%' }}
            onPress={() => respondToCaregiverAgreement(request.id, true)}
          >
            {getText('authorize')}
          </Button>
        </SafeAreaView>
      }
    >
      <ScrollView>
        <Text style={styles.headerTitle}>
          {getText('caregiver-approval-agreement')}
        </Text>
        <View style={styles.contentAlignmentCenter}>
          <Text style={styles.modalText}>
            {getText('puc-permission-access-to-records', {
              firstName: request.caregiver_first_name,
              lastName: request.caregiver_last_name,
            })}
          </Text>
          <View style={styles.lineStyle}>
            <Text style={styles.listItemTitleMobile}>
              {getText('if-you-authorize-person')}
            </Text>
          </View>
          <View style={styles.leftSpacing}>
            <FlatList
              data={[
                { key: getText('manage-prescriptions') },
                { key: getText('order-refills-prescriptions') },
                { key: getText('pick-up-prescriptions') },
                {
                  key: getText('discuss-care', {
                    pharmacyName: pharmacyName,
                  }),
                },
                {
                  key: getText('manage-health'),
                },
              ]}
              renderItem={({ item }) => {
                return (
                  <View style={{ marginBottom: 6 }}>
                    <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                  </View>
                );
              }}
            />
          </View>
          <Text
            style={[
              styles.modalHeader,
              {
                marginTop: theme.getSpacing(2),
                fontFamily: theme.fonts.medium.fontFamily,
              },
            ]}
          >
            {getText('access-validity')}
          </Text>
          <Text style={styles.modalText}>
            {getText('revoke-access-in-settings')}
          </Text>
        </View>
      </ScrollView>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  nameText: {
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '700',
    fontFamily: 'Lato_700Bold',
    fontSize: 14,
    lineHeight: 22,
    color: theme.palette.error[600],
  },
  textContainer: { flexDirection: 'row', alignItems: 'center' },
  leftSpacing: {
    marginLeft: theme.getSpacing(1),
    fontFamily: theme.fonts.medium.fontFamily,
  },
  pointer: {
    //@ts-ignore
    [Platform.OS === 'web' ? 'cursor' : undefined]: 'pointer', // only for web purposes
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  lineStyle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  requestHeaderStyle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontFamily: 'Lato_700Bold',
    color: theme.palette.gray[900],
  },
  emailTextStyle: {
    color: theme.palette.gray[900],
    fontSize: 12,
    lineHeight: 18,
    fontWeight: '400',
  },
  requestContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: '400',
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: '700',
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  listItemTitle: {
    lineHeight: 20,
    fontWeight: '600',
    fontSize: 18,
    color: theme.palette.gray[900],
  },

  listItemTitleMobile: {
    lineHeight: 20,
    fontFamily: 'Lato_700Bold',
    fontSize: 18,
    color: theme.palette.gray[900],
    marginBottom: theme.getSpacing(1),
  },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  declineButtonStyle: {
    marginEnd: theme.getSpacing(2),
    width: '50%',
    borderWidth: 1,
  },
  authorizationContainer: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(3),
  },
  rightSpacing: {
    marginRight: theme.getSpacing(0.5),
    marginTop: theme.getSpacing(0.5),
  },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
  },
  requestAuthorizedStyle: {
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
  contentAlignmentCenter: { alignContent: 'center' },
  rowDirection: { flexDirection: 'row' },
  headerTitle: {
    fontFamily: theme.fonts.medium.fontFamily,
    fontSize: 18,
    fontWeight: '600',
    lineHeight: 28,
    marginBottom: theme.getSpacing(1),
    textAlign: 'center',
  },
}));

type MedicalInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  'medical-info'
>;
