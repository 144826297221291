import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { AccountStackParamList } from '../AccountNavigation';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { View, Image } from 'react-native';
import { Text } from 'assets/components/text';
import { Button } from 'assets/components/button';
import { AddPersonIcon, PlusCircleIcon } from 'assets/icons';
import { PatientUnderCareStackParamList } from './PatientUnderCareNavigation';
import { refreshCaregiverRequestsState } from './patient-actions';
import { usePatientUnderCareState } from './patient-store';

export const PatientUnderCare: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { caregiverRequests } = usePatientUnderCareState();

  useEffect(() => {
    refreshCaregiverRequestsState();
  }, []);

  const onPress = async () => {
    navigation.navigate('add-patient-under-care-info');
  };

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <View>
          <AddPersonIcon color={theme.palette.white} />
        </View>
        <Text style={styles.textTitle}>
          {getText('people-under-your-care')}
        </Text>
        <View style={styles.textContainer}>
          <View style={{ marginTop: theme.getSpacing(2) }}>
            <Text style={[styles.text]}>
              {getText('people-under-your-care-empty-page')}
            </Text>
          </View>
        </View>
        <Button
          onPress={onPress}
          hierarchy="primary"
          icon={PlusCircleIcon}
          logger={{
            id: 'add-person-or-pet-submit-button',
          }}
        >
          {getText('add-person-or-pet')}
        </Button>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.getSpacing(4),
    alignItems: 'center',
    paddingTop: theme.getSpacing(4),
    paddingBottom: theme.getSpacing(2),
    alignSelf: 'center',
    display: 'flex',
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 18,
    marginTop: theme.getSpacing(3),
    lineHeight: 28,
  },
  text: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
    lineHeight: 20,
    textAlign: 'center',
  },
  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginRight: theme.getSpacing(2),
    flexWrap: 'wrap',
  },
}));

type PatientUnderCareProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  'patient-under-care'
>;
