import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { useTheme } from 'assets/theme';
import { View } from 'react-native';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Button } from 'assets/components/button';
import { UseFormReturn } from 'react-hook-form';
import { PatientUnderCareForm } from '../../patient-actions';
import { usePatientUnderCareState } from '../../patient-store';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { Text } from 'assets/components/text';
import { FunctionComponent, PropsWithChildren } from 'react';
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from 'assets/components/typeahead-with-tags';
import refillService from '../../../../refill/refill-service';
import {
  BoolString,
  MedicalInfoFormFields,
} from '../../../../../components/medical-info/MedicalInfoFormFields';

export const EditMedicalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    record?: RecordUnderCareDto;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ patientRecord, changeViewMode, handleSave, record, form }) => {
  const theme = useTheme();
  const { status, error } = usePatientUnderCareState();

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'medical_conditions',
    typeaheadWithTagsProps: {
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: getText('medical-conditions'),
      defaultValue: form.getValues().medical_conditions,
      tags: refillService.topMedicalConditionsHints,
    },
  };

  const handleSubmit = () => {
    changeViewMode(false);

    const { allergies, prefers_easy_open_bottle_caps_str } = form.getValues();

    const prefers_easy_open_bottle_caps =
      prefers_easy_open_bottle_caps_str === BoolString.True;

    handleSave({ allergies, prefers_easy_open_bottle_caps });
  };

  return (
    <View>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <MedicalInfoFormFields />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            onPress={form.handleSubmit(handleSubmit)}
            hierarchy="primary"
            loading={status === 'loading'}
            logger={{ id: 'personal-info-save-button' }}
          >
            {getText('save')}
          </Button>
        </Form.Actions>
      </Form>
    </View>
  );
};
