import {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useMemo,
} from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { ConversationStackParamList } from './ConversationNavigation';
import { ChatBox } from './Chatbox';
import { IMessage, User } from 'react-native-gifted-chat';
import UnifiedCommsService from '../../api/unified-comms-service';
import {
  AuthorType,
  DirectMessagePatientDto,
  Author,
  DirectMessageExtendedResponseDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import { Avatar } from 'assets/components/avatar';
import theme, { makeStyles } from 'assets/theme';
import { useUserState } from '../../store/user-store';
import { View } from 'react-native';
import { DirectMessageExtended } from './types';
import {
  setSelectedConversation,
  setSelectedMessages,
  setViewedConversations,
} from './messages-actions';
import { useMessagesState } from './messages-store';

export const ConversationBox: FunctionComponent<
  PropsWithChildren<ConversationBoxProps>
> = ({ route }) => {
  const conversationId = route.params.conversationId;
  const locationPatientRecordId = route.params.locationPatientRecordId;
  const locationId = route.params.locationId;
  const patientViewedAllMessages = route.params.patientViewedAllMessages;
  const pharmacyViewedAllMessages = route.params.pharmacyViewedAllMessages;
  const styles = useStyles();
  const { user } = useUserState();
  const { selectedMessages, viewedConversations, rawConversations } =
    useMessagesState();
  const pharmacyId = user?.pharmacyId;

  const chatUser: User | undefined = useMemo(() => {
    if (user?.firstName && user.lastName) {
      const fullName = `${user.firstName} ${user.lastName}`;
      return {
        _id: user.id,
        name: fullName,
        avatar: function () {
          return (
            <Avatar
              size={32}
              name={fullName}
              color={theme.palette.primary[800]}
            />
          );
        },
      };
    } else {
      return undefined;
    }
  }, [user]);

  const onSend = useCallback((newMessages: IMessage[]) => {
    if (pharmacyId) {
      void (async () => {
        await UnifiedCommsService.createMessage(
          pharmacyId,
          locationId,
          locationPatientRecordId,
          conversationId,
          {
            author_id: user.id,
            author_type: AuthorType.Patient,
            content: newMessages[0].text,
            patient_viewed_all_messages: true,
            pharmacy_viewed_all_messages: false,
            attachments: [],
          },
        );

        const conversationData = await getConversation(
          locationPatientRecordId,
          conversationId,
        );
        const messageData = getMessages(conversationData);
        setSelectedMessages(messageData);
      })();
    }
  }, []);

  async function getConversation(
    locationPatientRecordId: string,
    conversationId: string,
  ) {
    if (!pharmacyId) return undefined;
    const data = await Promise.all([
      UnifiedCommsService.getAllMessagesByConversation(
        user.pharmacyId,
        locationId,
        locationPatientRecordId,
        conversationId,
      ),
      UnifiedCommsService.getConversation(
        pharmacyId,
        locationId,
        locationPatientRecordId,
        conversationId,
      ),
    ]);

    return data;
  }

  function getMessages(
    conversationData:
      | [DirectMessageExtendedResponseDto, DirectMessagePatientDto]
      | undefined,
  ): IMessage[] {
    let IMessages: IMessage[] = [];

    if (!conversationData) return IMessages;
    const messages = conversationData[0].messages as DirectMessageExtended[];
    const conversation: DirectMessagePatientDto = conversationData[1];
    const authors = conversation.all_authors
      .filter((author) => Boolean(author))
      .reduce(
        (accumulator: Record<string, string>, author: Author) => ({
          ...accumulator,
          [author._id]: `${author.first_name} ${author.last_name}`,
        }),
        {},
      );

    IMessages = messages.map((message) => {
      const isOwner = chatUser?._id === message.author_id;
      const companionName = authors[message.author_id];
      return {
        _id: message.id,
        user: isOwner
          ? { ...chatUser, _id: chatUser._id }
          : {
              _id: message.author_id,
              avatar: function () {
                return (
                  <Avatar
                    size={32}
                    name={companionName}
                    color={theme.palette.gray[100]}
                  />
                );
              },
              name: companionName,
            },
        text: message.content,
        createdAt: new Date(message.created_at),
      };
    });

    IMessages.sort((a, b) => {
      return b.createdAt.valueOf() - a.createdAt.valueOf();
    });

    return IMessages;
  }

  useMemo(() => {
    void (async () => {
      setSelectedConversation(route.params.conversationId);

      const conversationData = await getConversation(
        locationPatientRecordId,
        conversationId,
      );

      const messageData = getMessages(conversationData);
      setSelectedMessages(messageData);

      if (!patientViewedAllMessages && pharmacyId) {
        const updatedConversation =
          await UnifiedCommsService.updateUserViewedStatus(
            pharmacyId,
            locationId,
            locationPatientRecordId,
            conversationId,
            {
              patient_viewed_all_messages: true,
              pharmacy_viewed_all_messages: pharmacyViewedAllMessages,
            },
          );

        setViewedConversations([
          ...viewedConversations,
          updatedConversation.id,
        ]);
      }
    })();
  }, [conversationId, rawConversations]);

  return (
    <View style={styles.container}>
      <ChatBox
        messages={selectedMessages}
        onSend={onSend}
        user={chatUser}
        conversationId={conversationId}
      />
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
}));

type ConversationBoxProps = NativeStackScreenProps<
  ConversationStackParamList,
  'conversation-box'
>;
