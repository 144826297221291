import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import shallow from 'zustand/shallow';
import { Text } from 'assets/components/text';
import { ClockIcon, GlobeIcon, LocationIcon } from 'assets/icons';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { useAppStateStore } from '../../../store/app-store';
import { Calendar } from '../../../components/calendar/Calendar';
import { formatDateTimeApi } from '../../../common/datetime-utils';
import moment from 'moment';
import { AppointmentTypeSlotDto } from '@digitalpharmacist/appointment-service-client-axios';
import { useBookAppointmentState } from './book-appointment-store';
import {
  enableNextButton,
  getAvailableSlots,
  setSelectedDate,
  setTimeSlots,
  getAvailableAppointmentLocations,
  setLocationOverride,
  setSelectedLocation,
} from './book-appointment-actions';
import { getMarkedDates, parseSlots } from './book-appointment-utils';
import { DateData, MarkedDates } from 'react-native-calendars/src/types';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { AppointmentInfo } from './AppointmentInfo';
import { TouchableOpacity } from '@gorhom/bottom-sheet';
import { StoreSelectorHandler } from '../../../components/store-selector/types';
import { StoreSelector } from '../../../components/store-selector';
import { useUserState } from '../../../store/user-store';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { useAppointmentsState } from '../appointments-store';

export const BookAppointmentDate: FunctionComponent<
  BookAppointmentDateProps
> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const {
    slots,
    slotsStatus,
    appointmentType,
    availableAppointmentLocationIds,
    location,
    locationOverride,
    appointmentTypeOverride,
    selectedLocation,
  } = useBookAppointmentState(
    (state) => ({
      ...state,
      availableAppointmentLocationIds: state.availableAppointmentLocations.map(
        (location) => location.location_id,
      ),
      appointmentTypeOverride: state.availableAppointmentLocations.find(
        (location) => location.location_id === state.locationOverride?.id,
      )?.appointment_type_ids[0],
    }),
    shallow,
  );
  const { pharmacyId, stores, getStores, availableStores } = useAppStateStore(
    (state) => ({
      ...state,
      availableStores: state.stores.filter((store) =>
        availableAppointmentLocationIds.includes(store.id),
      ),
    }),
    shallow,
  );

  const [appointmentSlots, setAppointmentSlots] = useState<AppointmentSlots>(
    {},
  );
  const [markedDates, setMarkedDates] = useState<MarkedDates>();
  const { selectedDate, step } = useBookAppointmentState();
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);
  const { user } = useUserState();
  const { location: appointmentsLocation } = useAppointmentsState();

  // Hardcoding the timezone for now. In the future it should be the pharmacy timezone.
  const timezone = 'America/Chicago';

  useEffect(() => {
    if (step === 0 && selectedDate) {
      selectDate(selectedDate);
    }

    if (stores.length < 1) void getStores();
  }, []);

  useEffect(() => {
    if (appointmentType && selectedLocation) {
      const locationAppointmentTypeId = locationOverride
        ? appointmentTypeOverride
        : appointmentType.id;

      if (
        locationAppointmentTypeId &&
        (!locationOverride || locationOverride.id === selectedLocation.id)
      ) {
        const startDate = moment().startOf('month');

        void getAvailableSlots(
          pharmacyId,
          selectedLocation.id,
          locationAppointmentTypeId,
          formatDateTimeApi(startDate),
          formatDateTimeApi(startDate.add(3, 'month')),
        );
      }

      void getAvailableAppointmentLocations(
        pharmacyId,
        appointmentType.appointment_group_id,
      );
    }
  }, [appointmentType, selectedLocation, appointmentTypeOverride]);

  useEffect(() => {
    if (slots) {
      const parsedSlots = parseSlots(slots);
      setAppointmentSlots(parsedSlots);

      if (!selectedDate) {
        setMarkedDates(getMarkedDates(parsedSlots));
      } else {
        selectDate(selectedDate, parsedSlots);
      }
    }
  }, [slots]);

  useEffect(() => {
    setSelectedLocation(locationOverride ? locationOverride : location);
  }, [locationOverride, location]);

  const handleDayPress = ({ dateString }: DateData) => {
    setSelectedDate(dateString);
    selectDate(dateString);
  };

  const selectDate = (date: string, slots?: AppointmentSlots) => {
    const timeSlots = slots ? slots[date] : appointmentSlots[date];

    setTimeSlots(timeSlots);
    enableNextButton();
    setMarkedDates({
      ...getMarkedDates(slots ? slots : appointmentSlots),
      [date]: { selected: true },
    });
  };

  const handleLocationChange = (location: PharmacyLocationDto) => {
    setLocationOverride(location);
  };

  return (
    <View style={{ flex: 1 }}>
      <Text style={styles.title}>{appointmentType?.title}</Text>
      {appointmentType?.description && (
        <Text style={styles.description}>{appointmentType.description}</Text>
      )}
      {selectedLocation && (
        <AppointmentInfo icon={LocationIcon}>
          <Text>{selectedLocation.name}</Text>
          {selectedLocation.address && (
            <>
              <Text style={{ color: theme.palette.gray[500] }}>
                {selectedLocation.address.address1}
                {selectedLocation.address.address2 &&
                  `, ${selectedLocation.address.address2}`}
              </Text>
              <Text style={{ color: theme.palette.gray[500] }}>
                {selectedLocation.address.city},{' '}
                {selectedLocation.address.state}{' '}
                {selectedLocation.address.postal_code}
              </Text>
            </>
          )}
        </AppointmentInfo>
      )}
      <AppointmentInfo icon={ClockIcon}>
        <Text>
          {appointmentType?.length} {getText('minutes')}
        </Text>
      </AppointmentInfo>
      <AppointmentInfo icon={GlobeIcon}>
        <Text>{timezone}</Text>
      </AppointmentInfo>
      {slotsStatus === 'loading' || !markedDates ? (
        <LoadingIndicator />
      ) : (
        <Calendar
          disabledByDefault={true}
          disableAllTouchEventsForDisabledDays={true}
          disableAllTouchEventsForInactiveDays={true}
          markedDates={markedDates}
          hideExtraDays={true}
          onDayPress={handleDayPress}
          markingType="custom"
        />
      )}

      {availableStores.length > 1 ? (
        <>
          <TouchableOpacity onPress={() => storeSelectorRef.current?.show()}>
            <Text style={styles.locationsLink}>
              {getText('explore-other-locations')}
            </Text>
          </TouchableOpacity>

          <StoreSelector
            ref={storeSelectorRef}
            options={availableStores}
            storeInformationShown={false}
            defaultOption={availableStores.find(
              (store) => store.id === selectedLocation?.id,
            )}
            onChange={handleLocationChange}
          />
        </>
      ) : null}
    </View>
  );
};

type AppointmentSlots = Record<string, AppointmentTypeSlotDto[]>;
export interface BookAppointmentDateProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 24,
    lineHeight: 32,
    marginVertical: theme.getSpacing(1),
  },
  description: {
    marginBottom: theme.getSpacing(2),
  },
  locationsLink: {
    color: theme.palette.primary[600],
    fontSize: 16,
    marginVertical: theme.getSpacing(3),
  },
}));
