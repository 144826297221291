import React, { FunctionComponent, useEffect, useState } from 'react';
import { View, useWindowDimensions, Platform, Modal } from 'react-native';
import theme, { useTheme } from 'assets/theme';
import { StyleSheet } from 'react-native';
import { Text } from 'assets/components/text';
import { useForm } from 'assets/form';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  PatientInsuranceForm,
  getFilename,
  uploadFile,
} from './patient-actions';
import { AccountStackParamList } from '../AccountNavigation';
import { Camera } from '../../../camera/Camera';
import { AlertTriangleIcon, ImageIcon, TrashIcon } from 'assets/icons';
import type { CameraCapturedPicture } from 'expo-camera';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getText, translations } from 'assets/localization/localization';
import { Form, ScreenContainer } from 'assets/layout';
import { Button } from 'assets/components/button';
import { usePatientIntakeState } from './patient-store';
import { useActionSheet } from '@expo/react-native-action-sheet';
import * as ImagePicker from 'expo-image-picker';
import ImageViewer from 'react-native-image-zoom-viewer';
import { FileUploadIcon } from 'assets/icons/FileUploadIcon';
import { useAppStateStore } from '../../../store/app-store';
import { useUserState } from '../../../store/user-store';
import patientService from '../../../api/patient-service';
import FileStorageService from '../../../api/file-storage-service';
import { PharmacyCategory } from '@digitalpharmacist/file-storage-service-client-axios';
import {
  CardType,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import {
  PhotoButton,
  PhotoInfo,
} from './components/insurance-card/PhotoButton';

export enum CardSide {
  Front = 'front',
  Back = 'back',
}

export enum CardSideMode {
  FrontOnly = 'front_only',
  FrontAndBack = 'front_and_back',
}

type FilenameProperty = keyof Pick<
  UpdatePatientRecordDto,
  | 'photo_id_url'
  | 'insurance_card_primary_front_url'
  | 'insurance_card_primary_back_url'
  | 'insurance_card_secondary_front_url'
  | 'insurance_card_secondary_back_url'
>;

export const cardTypeConfig: Record<
  CardType,
  {
    category: PharmacyCategory;
    editTitleCopy: translations;
    viewTitleCopy: translations;
    frontFilenameProperty: FilenameProperty;
  } & (
    | { cardSideMode: CardSideMode.FrontOnly; backFilenameProperty: undefined }
    | {
        cardSideMode: CardSideMode.FrontAndBack;
        backFilenameProperty: FilenameProperty;
      }
  )
> = {
  [CardType.PhotoId]: {
    cardSideMode: CardSideMode.FrontOnly,
    category: PharmacyCategory.PhotoId,
    editTitleCopy: 'photoId',
    viewTitleCopy: 'photo-id',
    frontFilenameProperty: 'photo_id_url',
    backFilenameProperty: undefined,
  },
  [CardType.InsurancePrimary]: {
    cardSideMode: CardSideMode.FrontAndBack,
    category: PharmacyCategory.InsuranceCard,
    editTitleCopy: 'primary-insurance-add-your-information',
    viewTitleCopy: 'primary',
    frontFilenameProperty: 'insurance_card_primary_front_url',
    backFilenameProperty: 'insurance_card_primary_back_url',
  },
  [CardType.InsuranceSecondary]: {
    cardSideMode: CardSideMode.FrontAndBack,
    category: PharmacyCategory.InsuranceCard,
    editTitleCopy: 'secondary-insurance-add-your-information',
    viewTitleCopy: 'secondary',
    frontFilenameProperty: 'insurance_card_secondary_front_url',
    backFilenameProperty: 'insurance_card_secondary_back_url',
  },
};

interface PatientCardState {
  isCameraVisible: boolean;
  cardSide?: CardSide;
}

export const EditPatientCard: FunctionComponent<PatientCardProps> = ({
  navigation,
  route: {
    params: { cardType },
  },
}) => {
  const theme = useTheme();
  const patientId = useUserState.getState().user?.patientRecordId;

  const { status, error } = usePatientIntakeState();

  const [{ isCameraVisible: isVisible, cardSide }, setIsCameraVisible] =
    useState<PatientCardState>({ isCameraVisible: false });
  const [errors, setErrors] = useState<CardErrors>();
  const [uploadTitle, setUploadTitle] = useState<string>();
  const { pharmacyId } = useAppStateStore();
  const { user } = useUserState();

  const cardSideMode = cardTypeConfig[cardType].cardSideMode;

  if (!patientId) {
    throw Error('No patient ID');
  }
  if (!user) {
    throw Error('There is no user data');
  }

  const { preferredPharmacyLocationId } = user;

  if (!preferredPharmacyLocationId) {
    throw Error('No location ID');
  }

  const [frontPhoto, setFrontPhoto] = useState<PhotoInfo>();
  const [backPhoto, setBackPhoto] = useState<PhotoInfo>();
  const [frontImageViewer, setFrontImageViewer] = useState<boolean>(false);
  const [backImageViewer, setBackImageViewer] = useState<boolean>(false);
  const [frontImageFileName, setFrontImageFileName] = useState<string>();
  const [backImageFileName, setBackImageFileName] = useState<string>();
  const methods = useForm<PatientInsuranceForm>({
    defaultValues: {
      frontPhoto: undefined,
      backPhoto: undefined,
    },
  });

  /**
   * Set photo uri here
   */
  const setPhotoUrlsFromPatientRecord = async () => {
    try {
      const patientRecord = await patientService.findPatientRecord(patientId);

      const { frontFilenameProperty, backFilenameProperty, category } =
        cardTypeConfig[cardType];

      const frontFilename = patientRecord[frontFilenameProperty];

      if (frontFilename) {
        const responseReadUrl = await FileStorageService.readUrl(
          category,
          frontFilename,
          pharmacyId,
        );
        setFrontPhoto({ uri: responseReadUrl.url });
      }

      if (backFilenameProperty) {
        const backFilename = patientRecord[backFilenameProperty];

        if (backFilename) {
          const responseReadUrl = await FileStorageService.readUrl(
            category,
            backFilename,
            pharmacyId,
          );
          setBackPhoto({ uri: responseReadUrl.url });
        }
      }
    } catch (error) {
      console.error('error setPhotoUrlsFromPatientRecord:', error);
    }
  };

  useEffect(() => {
    setPhotoUrlsFromPatientRecord();
  }, [cardTypeConfig]);

  useEffect(() => {
    setUploadTitle(getText(cardTypeConfig[cardType].editTitleCopy));
  }, []);

  const handleCameraClose = () => {
    setIsCameraVisible({ isCameraVisible: false });
  };

  const handleSavePhoto = (photo: CameraCapturedPicture) => {
    if (cardType === CardType.PhotoId) {
      setFrontPhoto(photo);
    } else {
      if (cardSide === CardSide.Back) {
        setBackPhoto(photo);
      } else if (cardSide === CardSide.Front) {
        setFrontPhoto(photo);
      }
    }
    setIsCameraVisible({ isCameraVisible: false });
  };

  const handleSubmit = async () => {
    // Validate form
    const validationErrors: CardErrors = {};

    if (!frontPhoto) {
      validationErrors.front = getText('front-photo-missing');
    }

    if (cardSideMode === CardSideMode.FrontAndBack && !backPhoto) {
      validationErrors.back = getText('back-photo-missing');
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }

    const patientId = useUserState.getState().user?.patientRecordId;

    if (!patientId) {
      throw new Error('Patient Id missing');
    }

    const { category, frontFilenameProperty, backFilenameProperty } =
      cardTypeConfig[cardType];

    try {
      if (!frontPhoto) {
        throw new Error('Undefined front photo when uploading');
      }

      if (!frontPhoto.uri) {
        throw new Error('Undefined uri when uploading');
      }

      const frontPhotoUri = frontPhoto.uri;

      const uploadPromises: Promise<string>[] = [];

      const uploadFrontImageFileName =
        frontImageFileName ?? getFilename(patientId);

      usePatientIntakeState.setState({
        error: undefined,
        status: 'loading',
      });

      const frontPromise = uploadFile(
        category,
        uploadFrontImageFileName,
        frontPhotoUri,
        pharmacyId,
      );

      uploadPromises.push(frontPromise);

      const uploadBackImageFileName =
        backImageFileName ?? getFilename(patientId);

      if (cardSideMode === CardSideMode.FrontAndBack) {
        if (!backPhoto?.uri) {
          throw new Error('Undefined uri when uploading back');
        }

        const backPhotoUri = backPhoto.uri;

        const backPromise = uploadFile(
          category,
          uploadBackImageFileName,
          backPhotoUri,
          pharmacyId,
        );

        uploadPromises.push(backPromise);
      }

      await Promise.all(uploadPromises);

      // update patient record
      let updateDto: UpdatePatientRecordDto = {
        [frontFilenameProperty]: uploadFrontImageFileName,
      };

      if (cardSideMode === CardSideMode.FrontAndBack) {
        if (!backFilenameProperty) {
          throw new Error('Property backFilename missing!');
        }

        updateDto = {
          ...updateDto,
          [backFilenameProperty]: uploadBackImageFileName,
        };
      }

      await patientService.updatePatientRecord(patientId, updateDto);

      usePatientIntakeState.setState({
        error: undefined,
        status: 'success',
      });

      navigation.navigate('insurance-and-id-card');
    } catch (e) {
      console.error('Error uploading insurance card:', e);
      usePatientIntakeState.setState({
        error: { message: getText('insurance-card-upload-error') },
        status: 'error',
      });
    }
  };

  const { showActionSheetWithOptions } = useActionSheet();

  const handleOpenActionSheet = (side: CardSide) => {
    if (cardType === CardType.PhotoId && frontPhoto) {
      return setFrontImageViewer(true);
    }
    if (frontPhoto && side === CardSide.Front) {
      return setFrontImageViewer(true);
    } else if (backPhoto && side === CardSide.Back) {
      return setBackImageViewer(true);
    }
    showActionSheetWithOptions(
      {
        options: [
          getText('take-photo'),
          getText('choose-photo'),
          getText('cancel'),
        ],
        cancelButtonIndex: 2,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          setIsCameraVisible({
            isCameraVisible: true,
            cardSide: side,
          });
        } else if (buttonIndex === 1) {
          choosePhoto(side);
        }
      },
    );
  };

  const choosePhoto = async (side: CardSide) => {
    const photo = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
    });
    if (!photo.canceled) {
      if (cardType !== CardType.PhotoId) {
        side === CardSide.Front
          ? setFrontPhoto(photo.assets[0])
          : setBackPhoto(photo.assets[0]);
      } else {
        setFrontPhoto(photo.assets[0]);
      }
    }
  };

  const resetImageSelection = async (side: CardSide) => {
    showActionSheetWithOptions(
      {
        options: [
          getText('take-photo'),
          getText('choose-photo'),
          getText('cancel'),
        ],
        cancelButtonIndex: 2,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          setIsCameraVisible({
            isCameraVisible: true,
            cardSide: side,
          });
        } else if (buttonIndex === 1) {
          choosePhoto(side);
        }
      },
    );
  };

  const removeSelectedImage = (side: CardSide) => {
    if (cardType === CardType.PhotoId) {
      setFrontPhoto(undefined);
    } else {
      if (side === CardSide.Front) {
        setFrontPhoto(undefined);
      } else {
        setBackPhoto(undefined);
      }
    }
  };

  const { width, height } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === 'web'
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);
  const buttonHeight = height * 0.25;

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          paddingVertical: theme.getSpacing(1),
        }}
      >
        {error && (
          <View style={{ marginVertical: theme.getSpacing(2) }}>
            <Form.Alert
              visible={!!error}
              intent={error ? 'warning' : 'success'}
              title={error.message}
            ></Form.Alert>
          </View>
        )}
        <Text
          style={{
            marginBottom: theme.getSpacing(1),
            fontSize: 16,
          }}
        >
          {uploadTitle}
        </Text>
      </View>
      <Text
        style={{
          color: theme.palette.gray[600],
          paddingVertical: theme.getSpacing(1),
        }}
      >
        {cardType === CardType.PhotoId
          ? getText('photo-take-card-picture')
          : getText('insurance-take-card-picture')}
      </Text>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <View style={stylesButton.imageOptions}>
            <Text style={stylesButton.label}>
              {' '}
              {cardType === CardType.PhotoId
                ? getText('photo')
                : getText('front-of-card')}
            </Text>
            {frontPhoto && (
              <View style={stylesButton.inline}>
                <TouchableOpacity
                  onPress={() => resetImageSelection(CardSide.Front)}
                >
                  <Text style={stylesButton.replaceText}>
                    {getText('replace')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => removeSelectedImage(CardSide.Front)}
                >
                  <TrashIcon size={20} color={theme.palette.gray[500]} />
                </TouchableOpacity>
              </View>
            )}
          </View>
          {errors && errors.front && (
            <Text style={stylesButton.errorLabel}>
              <AlertTriangleIcon color={theme.palette.error[600]} size={14} />{' '}
              {errors.front}
            </Text>
          )}
          <Form.Row>
            <PhotoButton
              uri={frontPhoto?.uri}
              width={buttonWidth}
              height={buttonHeight}
              onPress={() => {
                handleOpenActionSheet(CardSide.Front);
              }}
            >
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <View>
                  <ImageIcon size={32} color={theme.palette.gray[500]} />
                </View>
                <View style={stylesButton.upload}>
                  <FileUploadIcon color={theme.palette.gray[500]} size={24} />
                  <Text style={stylesButton.uploadText}>
                    {getText('upload')}
                  </Text>
                </View>
              </View>
            </PhotoButton>
          </Form.Row>
          {cardSideMode === CardSideMode.FrontAndBack && (
            <View>
              <View style={stylesButton.imageOptions}>
                <Text style={stylesButton.label}>
                  {getText('back-of-card')}
                </Text>
                {backPhoto && (
                  <View style={stylesButton.inline}>
                    <TouchableOpacity
                      onPress={() => resetImageSelection(CardSide.Back)}
                    >
                      <Text style={stylesButton.replaceText}>
                        {getText('replace')}
                      </Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                      onPress={() => removeSelectedImage(CardSide.Back)}
                    >
                      <TrashIcon size={20} color={theme.palette.gray[500]} />
                    </TouchableOpacity>
                  </View>
                )}
              </View>
              {errors && errors.back && (
                <Text style={stylesButton.errorLabel}>
                  {' '}
                  <AlertTriangleIcon
                    color={theme.palette.error[600]}
                    size={14}
                  />{' '}
                  {errors.back}
                </Text>
              )}
              <Form.Row>
                <PhotoButton
                  uri={backPhoto?.uri}
                  width={buttonWidth}
                  height={buttonHeight}
                  onPress={() => {
                    handleOpenActionSheet(CardSide.Back);
                  }}
                >
                  <View
                    style={{
                      padding: theme.getSpacing(1),
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <View>
                      <ImageIcon size={32} color={theme.palette.gray[500]} />
                    </View>
                    <View style={stylesButton.upload}>
                      <FileUploadIcon
                        color={theme.palette.gray[500]}
                        size={24}
                      />
                      <Text style={stylesButton.uploadText}>
                        {getText('upload')}
                      </Text>
                    </View>
                  </View>
                </PhotoButton>
              </Form.Row>
            </View>
          )}
        </Form>
        <Camera
          title={
            cardSide === CardSide.Front
              ? getText('add-front-of-card')
              : getText('add-back-of-card')
          }
          onSave={handleSavePhoto}
          isVisible={isVisible}
          onClose={handleCameraClose}
          mask={'card'}
        />
      </View>
      <Form.Actions>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('home');
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[700],
              fontSize: 16,
              marginTop: theme.getSpacing(1),
              marginBottom: theme.getSpacing(3),
              alignSelf: 'center',
            }}
          >
            {getText('insurance-bring-to-store')}
          </Text>
        </TouchableOpacity>
        <Button
          onPress={handleSubmit}
          hierarchy="primary"
          loading={status === 'loading'}
          logger={{ id: 'patient-insurance-submit-button' }}
        >
          {getText('next')}
        </Button>
      </Form.Actions>
      <Modal visible={frontImageViewer}>
        {Platform.OS === 'web' && (
          <View style={stylesButton.closeButtonContainer}>
            <TouchableOpacity
              onPress={() => setFrontImageViewer(!frontImageViewer)}
              style={stylesButton.closeModalButton}
            >
              <Text style={{ color: theme.palette.white }}>
                {getText('close')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <ImageViewer
          imageUrls={[{ url: frontPhoto?.uri! }]}
          index={0}
          onSwipeDown={() => setFrontImageViewer(false)}
          enableSwipeDown={true}
        />
      </Modal>

      <Modal visible={backImageViewer}>
        {Platform.OS === 'web' && (
          <View style={stylesButton.closeButtonContainer}>
            <TouchableOpacity
              style={stylesButton.closeModalButton}
              onPress={() => setBackImageViewer(!backImageViewer)}
            >
              <Text style={{ color: theme.palette.white }}>
                {getText('close')}
              </Text>
            </TouchableOpacity>
          </View>
        )}
        <ImageViewer
          imageUrls={[{ url: backPhoto?.uri! }]}
          index={0}
          onSwipeDown={() => setBackImageViewer(false)}
          enableSwipeDown={true}
        />
      </Modal>
    </ScreenContainer>
  );
};

type PatientCardProps = NativeStackScreenProps<
  AccountStackParamList,
  'edit-patient-card'
>;

const stylesButton = StyleSheet.create({
  closeModalButton: {
    padding: theme.getSpacing(1),
    backgroundColor: theme.palette.error[600],
    borderRadius: 5,
    margin: theme.getSpacing(1),
  },
  closeButtonContainer: {
    width: 70,
  },
  label: {
    fontSize: 16,
    fontWeight: '400',
    lineHeight: 24,
    textAlign: 'left',
  },
  imageOptions: {
    marginBottom: theme.getSpacing(1),
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  upload: {
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: theme.getSpacing(1),
  },
  uploadText: {
    color: theme.palette.gray[500],
    marginLeft: theme.getSpacing(1),
    fontSize: 16,
  },
  inline: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  replaceText: {
    color: theme.colors.primary,
    fontSize: 14,
    fontWeight: '500',
    marginRight: 12,
  },
  errorLabel: {
    color: theme.palette.error[600],
    fontSize: 14,
    fontWeight: '400',
  },
});

type CardErrors = {
  front?: string;
  back?: string;
};
