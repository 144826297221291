import { TextField } from 'assets/components/text-field';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import { DropdownSelect } from 'assets/components/dropdown-select';
import { PatientUnderCareForm } from '../patient-actions';
import { FunctionComponent, PropsWithChildren, useState } from 'react';
import states from '../../../../types/states';
import { UseFormReturn } from 'react-hook-form';

export const Address: FunctionComponent<
  PropsWithChildren<{
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const formValues = form.getValues();
  const [address1, setAddress1] = useState(formValues.address1);
  const [address2, setAddress2] = useState(formValues.address2);
  const [city, setCity] = useState(formValues.city);
  const [postal_code, setPostalCode] = useState(formValues.postal_code);

  const updateForm = () => {
    form.setValue('address1', address1);
    form.setValue('address2', address2);
    form.setValue('city', city);
    form.setValue('postal_code', postal_code);
    if (!formValues.state) form.setValue('state', '');
    return form;
  };

  return (
    <View>
      <Text style={{ marginBottom: theme.getSpacing(1) }}>
        {getText('address')}
      </Text>
      <TextField
        style={styles.marginBottom}
        name={'address1'}
        label={getText('street-address')}
        rules={{
          ...((formValues.city !== '' ||
            formValues.postal_code !== '' ||
            formValues.state !== '') && {
            required: getText('street-address-is-required'),
          }),
        }}
        value={formValues.address1}
        onChange={(value) => {
          setAddress1(value);
        }}
        onBlur={updateForm}
      />
      <TextField
        style={styles.marginBottom}
        name={'address2'}
        label={getText('street-address-line-2')}
        value={formValues.address2}
        onChange={(value) => {
          setAddress2(value);
        }}
        onBlur={updateForm}
      />
      <View style={styles.rowDirection}>
        <View style={styles.cityAndZipCodeText}>
          <TextField
            name={'city'}
            label={getText('city')}
            rules={{
              ...((formValues.address1 !== '' ||
                formValues.postal_code !== '' ||
                formValues.state !== '') && {
                required: getText('city-is-required'),
              }),
            }}
            onSubmit={form.handleSubmit(updateForm)}
            value={formValues.city}
            onChange={(value) => {
              setCity(value);
            }}
            onBlur={updateForm}
          />
        </View>
        <View style={styles.dropDownSelectContainer}>
          <DropdownSelect
            options={states[formValues.country == 'CA' ? 'CA' : 'US']}
            fieldName="state"
            label={getText('state')}
            labelInlined={true}
          ></DropdownSelect>
        </View>
      </View>
      <View style={styles.rowDirection}>
        <View style={styles.cityAndZipCodeText}>
          <TextField
            name="postal_code"
            label={getText('zip-code')}
            rules={{
              ...((formValues.address1 !== '' ||
                formValues.city !== '' ||
                formValues.state !== '') && {
                required: getText('zip-code-is-required'),
              }),
            }}
            value={formValues.postal_code}
            onSubmit={form.handleSubmit(updateForm)}
            onChange={(value) => {
              setPostalCode(value);
            }}
            onBlur={updateForm}
            style={styles.marginBottom}
          />
        </View>
        <View style={styles.dropDownSelectContainer}>
          <DropdownSelect
            fieldName="country"
            label={getText('country')}
            labelInlined={true}
            options={[
              {
                label: 'US',
                value: 'US',
              },
              {
                label: 'CA',
                value: 'CA',
              },
            ]}
          />
        </View>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  dropDownSelectContainer: {
    flex: 0.5,
  },
  rowDirection: {
    flexDirection: 'row',
  },
  cityAndZipCodeText: { flex: 1, marginRight: getSpacing(2) },
  marginBottom: { marginBottom: theme.getSpacing(2) },
}));
