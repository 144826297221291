import { Button } from 'assets/components/button';
import { Text } from 'assets/components/text/Text';
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from 'assets/components/typeahead-with-tags';
import { useForm } from 'assets/form';
import { Form, ScreenContainer } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RefillGuestTestIDs } from '../../refill-guest/RefillGuestTestIDs';
import refillService from '../refill-service';
import {
  useRefillDetailsStore,
  useRefillMedicalInformationStore,
  useRefillMedicationsStore,
} from '../refill-store';
import { RefillStackParamList } from '../RefillNavigation';
import patientService from '../../../api/patient-service';
import { useUserState } from '../../../store/user-store';
import { PatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { View } from 'react-native';
import {
  noKnownAllergiesOption,
  noKnownAllergiesValue,
} from '../../../api/medication-service';

export const OtherInformationModal: FunctionComponent<
  PropsWithChildren<OtherInformationNavigationProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { patientMedicalInfos, updateMedicalInformationData } =
    useRefillMedicalInformationStore();
  const { easyOpenBottleCaps, prescriptionsTogether, updateRefillDetailsData } =
    useRefillDetailsStore();
  const { selectedPatientRecord, updateMedicationsData } =
    useRefillMedicationsStore();

  const form = useForm({
    defaultValues: {
      ...patientMedicalInfos,
      easyBottleCaps: easyOpenBottleCaps,
      prescriptionsTogether: prescriptionsTogether,
    },
  });

  useEffect(() => {
    if (!user?.patientRecordId || !selectedPatientRecord) return;

    const allergies =
      selectedPatientRecord.allergies === undefined ||
      selectedPatientRecord.allergies.length === 0
        ? []
        : selectedPatientRecord.allergies.filter(
            (v) => v === noKnownAllergiesValue,
          ).length != 0
        ? [{ value: noKnownAllergiesValue, text: '' }]
        : selectedPatientRecord.allergies.map((v) => ({ value: v, text: v }));

    form.setValue('gender', selectedPatientRecord.gender || '');
    form.setValue('allergies', allergies);
    form.setValue(
      'medicalConditions',
      selectedPatientRecord.medical_conditions.map((v) => ({
        value: v,
        text: v,
      })),
    );

    form.setValue(
      'easyBottleCaps',
      selectedPatientRecord.prefers_easy_open_bottle_caps ? 'yes' : 'no',
    );

    if (selectedPatientRecord.prefers_med_sync !== null) {
      form.setValue(
        'prescriptionsTogether',
        selectedPatientRecord.prefers_med_sync ? 'yes' : 'no',
      );
    }
  }, [selectedPatientRecord]);

  const handleOnPressDone = async () => {
    updateMedicalInformationData(form.getValues());

    if (
      !user?.patientRecordId ||
      !updateMedicationsData ||
      !updateRefillDetailsData ||
      !selectedPatientRecord
    )
      return;

    const patientMedicalInfos = {
      id: user.patientRecordId,
      allergies: form.getValues().allergies.map((v) => v.value),
      medical_conditions: form
        .getValues()
        .medicalConditions.map((v) => v.value),
      prefers_easy_open_bottle_caps: form.getValues().easyBottleCaps === 'yes',
    };

    updateRefillDetailsData({
      easyOpenBottleCaps: form.getValues().easyBottleCaps ? 'yes' : 'no',
      prescriptionsTogether: form.getValues().prescriptionsTogether,
    });

    updateMedicationsData({
      selectedPatientRecord: {
        ...selectedPatientRecord,
        ...patientMedicalInfos,
      },
    });

    await patientService.updatePatientRecord(
      patientMedicalInfos.id,
      patientMedicalInfos,
    );

    navigation.navigate('refill-review');
  };

  const allergiesFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'allergies',
    rules: { required: { value: true, message: 'This field is required *' } },
    typeaheadWithTagsProps: {
      label: 'Allergies *',
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchAllergies(searchTerm);
      },
      hintMessage: 'Your allergies',
      emptyValue: noKnownAllergiesOption,
      tags: refillService.topAllergiesHints,
    },
  };

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'medicalConditions',
    typeaheadWithTagsProps: {
      // label: "Medical conditions",
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: 'Medical conditions',
      tags: refillService.topMedicalConditionsHints,
    },
  };

  return (
    <ScreenContainer style={{ marginTop: theme.getSpacing(2) }}>
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...allergiesFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={{ marginVertical: theme.getSpacing(1) }}>
              More information
            </Text>
            <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText('easy-open-bottle-caps-confirm')}
              rules={{
                required: getText('field-required'),
              }}
              name="easyBottleCaps"
              values={[
                {
                  text: getText('yes-easy-caps'),
                  value: 'yes',
                },
                {
                  text: getText('no-safety-caps'),
                  value: 'no',
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.paragraph}>
              {getText('easy-open-bottle-caps-description')}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              onPress={form.handleSubmit(handleOnPressDone)}
              testID={RefillGuestTestIDs.refillGuestFindStoreNext}
              logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
            >
              {getText('review')}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

type OtherInformationNavigationProps = NativeStackScreenProps<
  RefillStackParamList,
  'refill-other-information'
>;
const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    width: '100%',
  },
  paragraph: {
    ...theme.fonts.regular,
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
}));
