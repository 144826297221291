import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import { logError } from 'assets/logging/logger';

export const initPushNotificationToken = () => {
  // push notifications are only applicable to actual devices, eg: no web or device emulators
  if (Platform.OS === 'web' || !Device.isDevice) {
    return;
  }
  registerForPushNotificationsAsync()
    //TODO: push token to backend so we can trigger notifications
    .then((token) =>
      console.log(
        `Push notification token "${token}", will be undefined if not on real iPhone or Android device`,
      ),
    ) //leave this
    .catch((err) => logError(err));
};

async function registerForPushNotificationsAsync() {
  let token;
  const { status: existingStatus } = await Notifications.getPermissionsAsync();
  let finalStatus = existingStatus;
  if (existingStatus !== 'granted') {
    const { status } = await Notifications.requestPermissionsAsync();
    finalStatus = status;
  }
  if (finalStatus !== 'granted') {
    logError(new Error('Failed to get push token for push notification!'));
    return;
  }
  token = (await Notifications.getExpoPushTokenAsync()).data;

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}
