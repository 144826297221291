import { getSpacing, makeStyles } from 'assets/theme';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { FunctionComponent } from 'react';
import { getText } from 'assets/localization/localization';
import { AllergyTypeaheadField } from './AllergyTypeaheadField';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';

// This is terrible and only used since our RadioButtons only accept string.
// This will make the string checking safer and more clear (only a little bit)
// Better solution here is to make RadioButton generic and accept booleans and enums generically.
export enum BoolString {
  True = 'true',
  False = 'false',
}

export const MedicalInfoFormFields: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <View>
      <View>
        <Text style={styles.fieldLabel}>{getText('allergies') + ' *'}</Text>
        <View style={styles.marginTop}>
          <AllergyTypeaheadField
            name="allergies"
            rules={{
              required: getText('allergies-required'),
            }}
          />
        </View>

        <Text style={styles.unAvailableText}>
          {getText('section-on-the-roadmap', {
            section: getText('medical-conditions'),
          })}
        </Text>

        <Text style={styles.fieldLabel}>
          {getText('would-like-easy-open-caps')}
        </Text>

        <RadioButtonGroupField
          name="prefers_easy_open_bottle_caps_str"
          testID={'easy-open-bottle-caps-test-id'}
          values={[
            {
              text: getText('prefers-easy-open-caps'),
              value: BoolString.True,
            },
            {
              text: getText('prefers-safety-caps'),
              value: BoolString.False,
            },
          ]}
        />
        <Text style={styles.finePrint}>
          {getText('easy-open-bottle-caps-description')}
        </Text>
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  fieldLabel: {
    color: theme.palette.gray[700],
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    lineHeight: 20,
  },
  marginTop: {
    marginTop: theme.getSpacing(1),
  },
  unAvailableText: {
    color: theme.palette.gray[700],
    fontSize: 14,
    textAlign: 'center',
    paddingBottom: theme.getSpacing(2),
  },
  finePrint: {
    color: theme.palette.gray[400],
    fontFamily: 'Lato_400Regular',
    fontSize: 14,
    fontWeight: '400',
    lineHeight: 20,
    paddingTop: getSpacing(1),
  },
}));
