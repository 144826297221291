import React, {
  ForwardedRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { getText } from 'assets/localization/localization';
import {
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps,
} from './CancelAppointmentResult';
import { CancelAppointmentResult } from '../CancelAppointmentResult';

export const CancelAppointmentResultModal: ForwardRefRenderFunction<
  CancelAppointmentResultHandler,
  CancelAppointmentResultProps
> = ({ booking }, ref: ForwardedRef<CancelAppointmentResultHandler>) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDismissModal = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleDismissModal,
  }));

  return (
    <Modal
      show={showModal}
      title={getText('cancel-appointment')}
      okButtonProps={{
        onPress: handleDismissModal,
        logger: { id: 'cancel-appointment-result-ok-button-modal' },
        text: getText('done'),
      }}
      dismissButtonProps={{
        onPress: handleDismissModal,
        logger: { id: 'cancel-appointment-result-dismiss-button-modal' },
      }}
      isScrollable={true}
      height={800}
    >
      <CancelAppointmentResult booking={booking} />
    </Modal>
  );
};
