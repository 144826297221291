import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Button } from 'assets/components/button';
import { View, StyleSheet, Image, useWindowDimensions } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { Divider, RadioButton } from 'react-native-paper';
import { RefillGuestTestIDs } from '../../refill-guest/RefillGuestTestIDs';
import { ScreenContainer } from 'assets/layout';
import { RefillStackParamList } from '../RefillNavigation';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useRefillReviewInsuranceStore } from '../refill-store';

export const ChooseInsuranceType: FunctionComponent<
  PropsWithChildren<ChooseInsuranceTypeNavigationProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const windowDimensions = useWindowDimensions();
  const primary = '';
  const secondary = '';
  const { insuranceValue, updateReviewInsuranceData } =
    useRefillReviewInsuranceStore();
  const handleChange = (newValue: string) => {
    if (updateReviewInsuranceData) {
      updateReviewInsuranceData({ insuranceValue: newValue });
    }
  };

  return (
    <ScreenContainer>
      <View style={{ padding: theme.getSpacing(1) }}>
        <View style={styles.container}>
          <Text style={styles.textTitle}>Insurance</Text>
        </View>
        <Divider />
        <View style={styles.container}>
          <RadioButton.Group
            onValueChange={(newValue) => handleChange(newValue)}
            value={insuranceValue}
          >
            <View style={styles.row}>
              <RadioButton value={'Primary'} />
              <Text
                style={{
                  alignSelf: 'flex-start',
                  marginTop: theme.getSpacing(1),
                }}
              >
                Primary
              </Text>
            </View>
            <Image
              source={{ uri: primary }}
              style={{ width: windowDimensions.width }}
            />
            <View style={styles.row}>
              <RadioButton value={'Secondary'} />
              <Text
                style={{
                  alignSelf: 'flex-start',
                  marginTop: theme.getSpacing(1),
                }}
              >
                Secondary
              </Text>
            </View>
            <Image
              source={{ uri: secondary }}
              style={{ width: windowDimensions.width }}
            />
          </RadioButton.Group>
        </View>
      </View>
      <View>
        <Button
          hierarchy="primary"
          onPress={() => navigation.navigate('refill-review')}
          disabled={insuranceValue === ''}
          testID={RefillGuestTestIDs.refillGuestFindStoreNext}
          logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
        >
          Review
        </Button>
      </View>
    </ScreenContainer>
  );
};

interface ChooseInsuranceTypeNavigationProps
  extends NativeStackScreenProps<
    RefillStackParamList,
    'refill-insurance-method'
  > {}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
}));
