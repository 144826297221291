import medicationService, {
  noKnownAllergiesOption,
  noKnownAllergiesValue,
} from '../../api/medication-service';
import { ResourceItem } from '../../screens/account/patient/patient-actions';

export const allergiesAreExplicitlyEmpty = (allergyIds: string[]) => {
  return allergyIds.length === 1 && allergyIds[0] === noKnownAllergiesValue;
};

export const findAllergiesById = async (
  allergyIds: string[],
): Promise<ResourceItem[]> => {
  if (allergiesAreExplicitlyEmpty(allergyIds)) {
    return [noKnownAllergiesOption];
  }

  const idsAsString = allergyIds.join(',');
  const allergies = await medicationService.allergyFind(idsAsString);
  return allergies;
};
