import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { makeStyles, useTheme } from 'assets/theme';
import { View, Pressable, Text, Platform } from 'react-native';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from '../../navigation/AuthNavigation';
import { Logo } from '../../components/logo/Logo';
import { ScreenContainer } from 'assets/layout';
import { AppleSignInButton } from '../../components/apple-sign-in-button/AppleSignInButton';
import { GoogleSignInButton } from '../../components/google-sign-in-button/GoogleSignInButton';
import { getText } from 'assets/localization/localization';
import { Button } from 'assets/components/button';
import { EmailIcon, FaceIdIcon } from 'assets/icons';
import { Icon } from 'assets/components/icon';
import { useAppStateStore } from '../../store/app-store';
import { BottomSheet } from 'assets/components/bottom-sheet';
import { BottomSheetModal, enableLogging } from '@gorhom/bottom-sheet';
import { googleLogin } from '../login/login-actions';
import { useDeepLinkingContext } from 'assets/core/deep-linking';
import * as Linking from 'expo-linking';
import { Modal } from 'assets/components/modal';
import { notImplementedAlert } from 'assets/utils/alert';
import PharmacyService from '../../api/pharmacy-service';
import {
  PolicyType,
  PharmacyPoliciesDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';

export const Intro: FunctionComponent<PropsWithChildren<IntroProps>> = ({
  navigation,
}) => {
  const { currentUrl } = useDeepLinkingContext();
  const theme = useTheme();
  const styles = useStyles();
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [policies, setPolicies] = useState<PharmacyPoliciesDto>({});
  const [policyModalSettings, setPolicyModalSettings] = useState({
    title: '',
    type: '',
    ref: React.useRef<BottomSheetModal>(null),
    state: false,
  });
  const pharmacyId = useAppStateStore((state) => state.pharmacyId);
  const pharmacyName = useAppStateStore((state) => state.pharmacyName);

  useEffect(() => {
    if (currentUrl && currentUrl !== '') {
      const parsedUrl = currentUrl.split('#');
      if (parsedUrl.length > 1) {
        const queryParams = parsedUrl[1];
        const parsedQueryParams = new URLSearchParams(queryParams);
        const idToken = parsedQueryParams.get('id_token');
        const accessToken = parsedQueryParams.get('access_token');
        if (idToken && accessToken) {
          googleLogin(
            idToken,
            accessToken,
            navigation as AuthStackNavigationProp,
          );
        }
      }
    }
  }, [currentUrl]);

  //TODO: This is a temporary fix for a bug, this needs to be resolved, I will write up a ticket
  useEffect(() => {
    enableLogging();
  }, []);

  useEffect(() => {
    if (pharmacyId) {
      void (async () => {
        const policiesResponse: PharmacyPoliciesDto =
          await PharmacyService.findPoliciesForPharmacy(pharmacyId);
        setPolicies(policiesResponse);
      })();
    }
  }, [pharmacyId]);

  const toggleIsNewPatient = () => {
    setIsNewPatient((prevValue) => !prevValue);
  };

  const continueWithEmail = async () => {
    if (currentUrl && currentUrl !== '') {
      const { queryParams } = Linking.parse(currentUrl);
      if (queryParams) {
        navigation.navigate('login-enter-email', queryParams as never);
      } else {
        navigation.navigate('login-enter-email');
      }
    } else {
      navigation.navigate('login-enter-email');
    }
  };

  const closePolicyModal = () => {
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: '',
      state: false,
      title: '',
    }));
    if (Platform.OS !== 'web') {
      policyModalSettings.ref.current?.dismiss();
    }
  };

  const openPolicyModal = (policyType: PolicyType) => {
    const policyTitle =
      policyType === PolicyType.TermsOfService
        ? getText('terms-of-service')
        : getText('privacy-policy');
    setPolicyModalSettings((prevState) => ({
      ...prevState,
      type: policyType,
      state: true,
      title: policyTitle,
    }));
    if (Platform.OS !== 'web') {
      policyModalSettings.ref.current?.present();
    }
  };

  const getPoliciesContent = (): ReactNode => {
    const continuationDisclaimer = getText('continuation-disclaimer');
    const noPolicyText = getText('contact-pharmacy-for-policy-info');
    const termsOfServiceBlock = (
      <Text
        style={styles.link}
        onPress={() => openPolicyModal(PolicyType.TermsOfService)}
      >
        {getText('terms-of-service')}
      </Text>
    );
    const privacyBlock = (
      <Text
        style={styles.link}
        onPress={() => openPolicyModal(PolicyType.PrivacyPolicy)}
      >
        {getText('privacy-policy')}
      </Text>
    );

    if (
      PolicyType.PrivacyPolicy in policies &&
      PolicyType.TermsOfService in policies
    ) {
      return (
        <>
          <>{continuationDisclaimer} </>
          <>{termsOfServiceBlock} </>
          <>{getText('and')} </>
          <>{privacyBlock}</>.
        </>
      );
    } else if (PolicyType.TermsOfService in policies) {
      return (
        <>
          <>{continuationDisclaimer} </>
          <>{termsOfServiceBlock}</>.{'\n'}
          <>{noPolicyText} </>
          <>{getText('privacy-policy')}</>.
        </>
      );
    } else if (PolicyType.PrivacyPolicy in policies) {
      return (
        <>
          <>{continuationDisclaimer} </>
          <>{privacyBlock}</>.{'\n'}
          <>{noPolicyText} </>
          <>{getText('terms-of-service')}</>.
        </>
      );
    } else {
      return (
        <>
          <>{noPolicyText} </>
          <>{getText('terms-of-service')} </>
          <>{getText('and')} </>
          <>{getText('privacy-policy')}</>.
        </>
      );
    }
  };

  return (
    <View style={styles.container}>
      <ScreenContainer backgroundColor={theme.colors.brandedPrimary}>
        <View style={{ flex: 1 }}>
          <View style={{ flex: 0.5 }}>
            <Logo />
          </View>
          {isNewPatient && (
            <View>
              <Text style={[styles.text, styles.thankYouText]}>
                {getText('thank-you-for-choosing')} {'\n'} {pharmacyName}!
              </Text>
              <Text style={[styles.text, styles.getStartedText]}>
                {getText('to-get-started-choose')}
              </Text>
            </View>
          )}
          <View style={{ flex: 1 }}>
            <View>
              <AppleSignInButton
                text={getText('apple-continue')}
                style={[
                  styles.marginBottom2,
                  isNewPatient && { marginTop: theme.getSpacing(4) },
                ]}
              />
              <GoogleSignInButton
                text={getText('google-continue')}
                style={styles.marginBottom2}
              />
              <Button
                icon={EmailIcon}
                style={[styles.button]}
                hierarchy={'secondary-gray'}
                onPress={continueWithEmail}
                logger={{ id: 'email-continue-button' }}
              >
                {getText('email-continue')}
              </Button>
            </View>
            <View>
              <Pressable onPress={toggleIsNewPatient}>
                <Text style={[styles.text, styles.marginTop3]}>
                  {isNewPatient
                    ? getText('go-back')
                    : getText('i-am-a-new-patient')}
                </Text>
              </Pressable>

              <Pressable onPress={notImplementedAlert}>
                <View style={[styles.container, styles.marginTop3]}>
                  <Icon icon={FaceIdIcon} size={32} />
                  <Text style={[styles.text, styles.marginTop1]}>
                    {getText('face-id')}
                  </Text>
                </View>
              </Pressable>
            </View>

            <View style={{ marginTop: 44, flex: 1 }}>
              <Text style={styles.text}>{getPoliciesContent()}</Text>
            </View>

            {Platform.OS === 'web' ? (
              <Modal
                title={policyModalSettings.title}
                show={policyModalSettings.state}
                okButtonProps={{
                  onPress: closePolicyModal,
                  logger: { id: 'policy-ok-button-modal' },
                  text: getText('close'),
                }}
                dismissButtonProps={{
                  onPress: closePolicyModal,
                  logger: { id: 'policy-dismiss-button-modal' },
                }}
                isScrollable
                height={800}
              >
                <Text style={styles.modalText}>
                  {policies[policyModalSettings.type as PolicyType]?.content}
                </Text>
              </Modal>
            ) : (
              <BottomSheet
                bottomSheetRef={policyModalSettings.ref}
                title={policyModalSettings.title}
                height={'90%'}
                onDismiss={closePolicyModal}
              >
                <View>
                  <Text style={styles.modalText}>
                    {policies[policyModalSettings.type as PolicyType]?.content}
                  </Text>
                </View>
              </BottomSheet>
            )}
          </View>
        </View>
      </ScreenContainer>
    </View>
  );
};

type IntroProps = NativeStackScreenProps<AuthStackParamList, 'intro'>;

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: theme.colors.brandedPrimary,
  },
  marginBottom2: {
    marginBottom: theme.getSpacing(2),
  },
  marginTop1: {
    marginTop: theme.getSpacing(1),
  },
  marginTop3: {
    marginTop: theme.getSpacing(3),
  },
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
  text: {
    color: theme.palette.white,
    textAlign: 'center',
  },
  link: {
    ...theme.fonts.medium,
    textDecorationLine: 'underline',
    color: theme.palette.white,
  },
  thankYouText: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 30,
  },
  getStartedText: {
    fontSize: 18,
    lineHeight: 28,
    marginTop: theme.getSpacing(3),
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
}));
