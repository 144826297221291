import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from 'react';
import { Modal } from 'assets/components/modal';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { View } from 'react-native';
import { PrescriptionDto } from '@digitalpharmacist/prescription-service-client-axios';
import { PrescriptionCard } from '../../../components/prescription-card';
import { makeStyles } from 'assets/theme';
import { useRefillMedicationsStore } from '../refill-store';
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from './UnableToSubmitProps';
import { UnableToSubmitTestID } from './UnableToSubmitTestID';

export const UnableToSubmitModalComponent = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(
  (
    {
      onChange = (value: string) => {},
      disabled = false,
      handleUnableToSubmit = (value: string) => {},
    }: UnableToSubmitProps,
    ref: ForwardedRef<UnableToSubmitHandler>,
  ) => {
    const styles = useStyles();
    const { allMedications, unableToSubmitMedications, updateMedicationsData } =
      useRefillMedicationsStore();

    const [showModal, setShowModal] = useState(false);

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnSave = () => {
      handleUnableToSubmit('continue');
      handleDismissModal();
    };

    const removeUnableToSubmitMedications = (item: PrescriptionDto) => {
      if (updateMedicationsData) {
        updateMedicationsData({
          allMedications: allMedications?.map((x) => ({
            ...x,
            checked: x.rx_number === item.rx_number ? false : x.checked,
          })),
          unableToSubmitMedications: unableToSubmitMedications?.filter(
            (med) => med.rx_number !== item.rx_number,
          ),
        });
      }
    };

    return (
      <Modal
        size="sm"
        title={getText('notice')}
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          testID: UnableToSubmitTestID.cancelModalButton,
          logger: { id: UnableToSubmitTestID.cancelModalButton },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          testID: UnableToSubmitTestID.okModalButton,
          logger: { id: UnableToSubmitTestID.okModalButton },
        }}
      >
        <View>
          <Text style={styles.textTitle}>
            {getText('unable-to-process-items')}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText('we-will-contact-prescriber')}
          </Text>
          <Text style={styles.textTitleDescription}>
            {getText('please-review-the-messages-below')}
          </Text>
          {unableToSubmitMedications?.map((medication, index) => (
            <PrescriptionCard
              prescription={medication}
              key={index}
              removable={true}
              showDetailsIcon={false}
              onRemove={() => removeUnableToSubmitMedications(medication)}
              patient={undefined}
            />
          ))}
        </View>
      </Modal>
    );
  },
);
const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
  textTitleDescription: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: '500',
    fontSize: 14,
    marginTop: theme.getSpacing(3),
  },
}));
