import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import { useTheme } from 'assets/theme';
import { Button } from 'assets/components/button';
import { useNavigation } from '@react-navigation/native';
import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';

export const ResetLinkSent: FunctionComponent<
  PropsWithChildren<ResetLinkSentProps>
> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const onPress = async () => {
    navigation.navigate('intro');
  };

  const goBack = async () => {
    navigation.goBack();
  };

  return (
    <ScreenContainer>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 25,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('reset-password')}
      </Text>
      <Text
        style={{
          color: theme.palette.gray[600],
          fontSize: 14,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: 'center',
        }}
      >
        {getText('reset-link-sent')}
      </Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={{ marginBottom: theme.getSpacing(1) }}>
          <Button
            onPress={onPress}
            hierarchy="tertiary"
            logger={{ id: 'reset-password-back-to-login-button' }}
          >
            {getText('return-to-login')}
          </Button>
        </View>
        <View style={{ marginBottom: theme.getSpacing(2) }}>
          <Button
            onPress={goBack}
            hierarchy="tertiary"
            logger={{ id: 'reset-password-back-button' }}
          >
            {getText('back')}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface ResetLinkSentProps {}
