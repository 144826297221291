import React, {
  FunctionComponent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { Text } from 'assets/components/text';
import { AccountTestIDs } from './AccountTestIDs';
import { useUserState } from '../../store/user-store';
import { makeStyles, useTheme } from 'assets/theme';
import { Avatar } from 'assets/components/avatar';
import { logout } from '../login/login-actions';
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
  useFocusEffect,
} from '@react-navigation/native';
import { AccountStackNavigationProp } from './AccountNavigation';
import { getText } from 'assets/localization/localization';
import { ScreenContainer } from 'assets/layout';
import { ListItemLink, ListMenu } from 'assets/components/list-menu';
import {
  usePatientSubmissionsState,
  usePatientUnderCareState,
} from './patient/patient-store';
import {
  getLatestUserSubmissionsByPatientRecordId,
  refreshCaregiverRequestsState,
} from './patient/patient-actions';
import moment from 'moment';
import patientService from '../../api/patient-service';
import { get } from 'cypress/types/lodash';
import shallow from 'zustand/shallow';
import { LandingHeader } from '../../components/landing-header';
import { useAppStateStore } from '../../store/app-store';

export const Account: FunctionComponent<PropsWithChildren<AccountProps>> = (
  props,
) => {
  const styles = useStyles();
  const theme = useTheme();
  const { user } = useUserState();
  const { recordsUnderCare, caregiverRequests } = usePatientUnderCareState();

  const { isSubmissionsLoading, latestSubmission } = usePatientSubmissionsState(
    (state) => ({
      isSubmissionsLoading: state.status === 'loading',
      latestSubmission: state.latestSubmission,
    }),
    shallow,
  );
  const fullName = `${user?.firstName} ${user?.lastName}`.trim();
  const navigation = useNavigation<AccountStackNavigationProp>();
  const navPUC = useNavigation<NavigationProp<ParamListBase>>();
  const patientId = useUserState.getState().user?.patientRecordId;
  const [insuranceSubtitle, setInsuranceSubtitle] = useState(
    getText('add-card'),
  );
  const { pharmacyName } = useAppStateStore();

  useEffect(() => {
    refreshCaregiverRequestsState();
  }, []);

  const loadPatientRecord = async () => {
    if (!patientId) {
      throw Error('No patient ID');
    }
    const patientRecord = await patientService.findPatientRecord(patientId);
    if (
      patientRecord.insurance_card_primary_front_url ||
      patientRecord.insurance_card_secondary_front_url ||
      patientRecord.photo_id_url
    ) {
      setInsuranceSubtitle(getText('primary-secondary-photo-id'));
    } else {
      setInsuranceSubtitle(getText('add-card'));
    }
  };

  useFocusEffect(
    useCallback(() => {
      void loadPatientRecord();
    }, [patientId]),
  );

  useFocusEffect(
    useCallback(() => {
      getLatestUserSubmissionsByPatientRecordId();
    }, []),
  );

  const onPress = async () => {
    navPUC.navigate('people-under-care', {
      screen: 'add-patient-under-care-info',
    });
  };

  const handleFullProfile = () => {
    // TODO: follow on card will fill out other profile screens such as this
  };

  const handleLogout = () => {
    logout();
  };

  const handleMedicalInfoClick = () => {
    navigation.navigate('medical-info');
  };

  const handlePersonalInfoClick = () => {
    navigation.navigate('personal-information');
  };

  const handleAboutAppClick = () => {
    navigation.navigate('about-app');
  };

  const handleInsuranceAndIdCardsClick = () => {
    navigation.navigate('insurance-and-id-card');
  };

  const handlePatientFormsClick = async () => {
    navigation.navigate('patient-forms');
  };

  const handleItemClick = () => {
    if (caregiverRequests.length > 0 || recordsUnderCare.length > 0) {
      navPUC.navigate('people-under-care', {
        screen: 'patient-under-care-list',
      });
    } else {
      navPUC.navigate('people-under-care', { screen: 'patient-under-care' });
    }
  };

  return (
    <>
      {Platform.OS !== 'web' && (
        <LandingHeader
          pharmacyName={pharmacyName}
          greetings={getText('your-account')}
        />
      )}
      <ScreenContainer>
        <View>
          <ListMenu style={styles.container}>
            <ListItemLink onPress={handleMedicalInfoClick}>
              <Text style={styles.textTitle}>{getText('medical-info')}</Text>
              <Text style={styles.textUnderTitle}>
                {getText('allergies-conditions-caregivers')}
              </Text>
            </ListItemLink>
            <ListItemLink onPress={handlePersonalInfoClick}>
              <Text style={styles.textTitle}>{getText('personal-info')}</Text>
              <Text style={styles.textUnderTitle}>
                {getText('name-phone-email')}
              </Text>
            </ListItemLink>
            <ListItemLink onPress={handleInsuranceAndIdCardsClick}>
              <Text style={styles.textTitle}>
                {getText('insurance-id-card')}
              </Text>
              {/* TODO Last updated timestamp should be shown here if there is any card image added */}
              <Text style={styles.textUnderTitle}>{insuranceSubtitle}</Text>
            </ListItemLink>
            <ListItemLink onPress={handleItemClick}>
              <Text style={styles.textTitle}>
                {getText('people-under-your-care')}
              </Text>
              <View>
                {recordsUnderCare && recordsUnderCare.length > 0 ? (
                  <Text style={styles.textUnderTitle}>
                    {recordsUnderCare
                      .slice(0, Math.min(2, recordsUnderCare.length))
                      .map((record) => record.record_under_care.first_name)
                      .join(', ')}
                    {recordsUnderCare.length > 2 &&
                      ` and ${recordsUnderCare.length - 2} ${getText(
                        'others',
                      )}`}
                  </Text>
                ) : (
                  <TouchableOpacity onPress={onPress}>
                    <Text style={styles.textUnderTitle}>
                      {getText('add-person')}...
                    </Text>
                  </TouchableOpacity>
                )}
              </View>
            </ListItemLink>
            <ListItemLink
              onPress={
                isSubmissionsLoading ? undefined : handlePatientFormsClick
              }
            >
              <Text style={styles.textTitle}>{getText('patient-forms')}</Text>
              {isSubmissionsLoading ? (
                <Text style={styles.textUnderTitle}>{`${getText(
                  'loading',
                )}...`}</Text>
              ) : (
                <Text style={styles.textUnderTitle}>
                  {latestSubmission
                    ? `${getText('last-submission')}: ${moment(
                        latestSubmission,
                        'YYYY-MM-DD',
                      ).format('MMM D, YYYY')}`
                    : getText('no-submissions')}
                </Text>
              )}
              {/* TODO add the data <Text>{getText("none")}</Text> */}
            </ListItemLink>
            <View style={{ marginTop: theme.getSpacing(1) }}>
              <ListItemLink onPress={handleAboutAppClick} showDivider={false}>
                <Text style={styles.touchableText}>
                  {getText('about-this-app')}
                </Text>
              </ListItemLink>
              <ListItemLink
                onPress={handleLogout}
                showDivider={false}
                showIcon={false}
              >
                <Text style={styles.touchableText}>{getText('sign-out')}</Text>
              </ListItemLink>
            </View>
          </ListMenu>
        </View>
        {
          //TODO: Add button for feedback widget back when BE is ready
          /*<View style={{ marginBottom: theme.getSpacing(2) }}>
        <Button
          onPress={() => {
            navigation.navigate("feedback");
          }}
          hierarchy="secondary-gray"
          logger={{ id: "profile-give-feedback-button" }}
        >
          {getText("feedback-give-feedback")}
        </Button>
      </View>*/
        }
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  name: {
    fontSize: 24,
    fontWeight: '500',
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 20,
    marginTop: theme.getSpacing(1),
    lineHeight: 30,
  },
  textUnderTitle: {
    ...theme.fonts.regular,
    color: theme.palette.gray[600],
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 24,
  },
  touchableText: {
    ...theme.fonts.regular,
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
  },
}));

interface AccountProps {}
