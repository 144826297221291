import { useEffect, useRef, useState } from 'react';
import {
  AuthorType,
  DirectMessagePatientDto,
} from '@digitalpharmacist/unified-communications-service-client-axios';
import {
  setIncomeMessage,
  setNewConversation,
  setRawConversations,
  setViewedConversations,
  setUpdatedUserStatus,
  setSocket,
  setSocketInitialized,
  buildMessageList,
  setCounts,
} from './messages-actions';
import { io } from 'socket.io-client';
import { useUserState } from '../../store/user-store';
import { EmittedMessage, EmittedUpdatedUserStatus, Order } from './types';
import usersService from '../../api/users-service';
import { INBOX_SOCKET_URL } from '../../common/constants';
import { StorageKeys } from '../../../enums/storage-keys';
import AsyncStorage from '@react-native-async-storage/async-storage';
import unifiedCommsService from '../../api/unified-comms-service';
import {
  PatientUserDto,
  PharmacyUserDto,
} from '@digitalpharmacist/users-service-client-axios';
import { compare } from '../../../../pharmacy/modules/screens/messages/utils';
import { useMessagesState } from './messages-store';

export const useSockets = () => {
  const { user } = useUserState();
  const { socket, socketInitialized } = useMessagesState();

  const [typingMember, setTypingMember] = useState<UserTyping | null>();
  const typingTimer = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!socket) {
      const init = async () => {
        const token = await AsyncStorage.getItem(StorageKeys.AccessToken);
        const socketInit = io(INBOX_SOCKET_URL, {
          query: {
            client: AuthorType.Patient,
          },
          auth: {
            token,
          },
          transports: ['websocket'],
        });
        setSocket(socketInit);
      };
      init();
    }
  }, [socket]);

  useEffect(() => {
    if (socket) {
      void (async () => {
        if (user?.preferredPharmacyLprId) {
          socket.emit('join', {
            type: AuthorType.Patient,
            id: user.preferredPharmacyLprId,
          });
          return () => {
            socket.emit('leave', {
              type: AuthorType.Patient,
              id: user.preferredPharmacyLprId,
            });
          };
        }
      })();
    }
  }, [socket, user?.preferredPharmacyLprId]);

  useEffect(() => {
    if (socket && !socketInitialized) {
      const onMessagesReceived = async (message: EmittedMessage) => {
        let author: PharmacyUserDto | PatientUserDto;
        try {
          if (message.author_type == AuthorType.Patient) {
            author = await usersService.getUser(message.author_id);
          } else {
            author = await usersService.getPharmacistUser(message.author_id);
          }

          setIncomeMessage(message, author);
        } catch (err) {}
      };

      const onTypingReceived = (userTyping: UserTyping) => {
        if (userTyping.id === user?.id) {
          return;
        }

        clearTimeout(typingTimer.current);
        setTypingMember(userTyping);

        typingTimer.current = setTimeout(() => {
          setTypingMember(null);
        }, 1000);
      };

      const onNewConversation = async (
        conversation: DirectMessagePatientDto,
      ) => {
        if (user?.patientRecordId) {
          if (user.preferredPharmacyLprId && user.preferredPharmacyLocationId) {
            const rawConversationsData: DirectMessagePatientDto[] =
              await unifiedCommsService.getAllConversationsByPatientAndLocation(
                user.pharmacyId,
                user.preferredPharmacyLocationId,
                user.preferredPharmacyLprId,
              );

            const newViewedConversations = rawConversationsData
              .filter(
                (conversation) => conversation.patient_viewed_all_messages,
              )
              .map((conversation) => conversation.conversation_id);

            const sortedConversationsData = rawConversationsData.sort(
              (currentConversation, nextConversation) =>
                compare(
                  currentConversation,
                  nextConversation,
                  'most_recent_qualifying_message_date',
                  Order.DESC,
                  true,
                ),
            );

            setRawConversations(sortedConversationsData);
            setViewedConversations(newViewedConversations);
            setNewConversation(conversation);
          }
        }
      };

      const onUpdatedUserStatus = async (
        updatedUserStatus: EmittedUpdatedUserStatus,
      ) => {
        await setUpdatedUserStatus(updatedUserStatus);
      };

      socket.on('new_conversation', onNewConversation);
      socket.on('typing', onTypingReceived);
      socket.on('message', onMessagesReceived);
      socket.on('updated_viewed_user_status', onUpdatedUserStatus);
      setSocketInitialized(true);
      return () => {
        socket.off('new_conversation', onNewConversation);
        socket.off('message', onMessagesReceived);
        socket.off('typing', onTypingReceived);
        socket.off('updated_viewed_user_status', onUpdatedUserStatus);
      };
    }
  }, [socket]);

  const onType = (conversationId: string) => {
    if (user?.preferredPharmacyLocationId && user.preferredPharmacyLprId) {
      const userTyping: UserTyping = {
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        location_id: user.preferredPharmacyLocationId,
        conversation_id: conversationId,
        location_patient_id: user.preferredPharmacyLprId,
        author_type: AuthorType.Patient,
      };
      socket?.emit('typing', userTyping);
    }
  };

  return {
    typingMember,
    onType,
  };
};

export const useInitializeSocketsAndData = () => {
  useSockets();
  const { rawConversations, viewedConversations } = useMessagesState();
  const { user } = useUserState();

  useEffect(() => {
    void (async () => {
      await buildMessageList();
    })();
  }, [user?.preferredPharmacyLprId]);

  useEffect(() => {
    const newViewedConversations = rawConversations
      .filter((conversation) => conversation.patient_viewed_all_messages)
      .map((conversation) => conversation.conversation_id);

    setViewedConversations(newViewedConversations);
  }, [rawConversations]);

  useEffect(() => {
    void (async () => {
      const count: number =
        rawConversations.length - viewedConversations.length;
      setCounts({ unread: count });
    })();
  }, [rawConversations, viewedConversations]);
};

export interface UserTyping {
  id: string;
  name: string;
  conversation_id: string;
  author_type: string;
  location_id: string;
  location_patient_id: string;
}
