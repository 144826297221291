import { TimeRangeDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import { getText } from 'assets/localization/localization';

export const isNumber = (str: string): boolean => {
  return /^\d+$/.test(str);
};

export const isZipCode = (str?: string): boolean => {
  return !!str && isNumber(str) && str.length === 5;
};

export const currentDayOpenStoreHours = (hoursAsStr: TimeRangeDto[]) => {
  const day = new Date().getDay();
  const todayOpenHours = hoursAsStr.find((openHour) => {
    return openHour.day === day;
  });
  return `${todayOpenHours?.start_hour}:00-  ${todayOpenHours?.end_hour}:00`.trim();
};

export const getDayAsStr = (hours: TimeRangeDto) => {
  switch (hours.day) {
    case 1:
      return getText('monday');
    case 2:
      return getText('tuesday');
    case 3:
      return getText('wednesday');
    case 4:
      return getText('thursday');
    case 5:
      return getText('friday');
    case 6:
      return getText('saturday');
    case 7:
      return getText('sunday');
    default:
      return '';
  }
};
