import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { MEDICATION_SERVICE_BASE_URL } from '../common/constants';

import {
  AllergyDto,
  DefaultApi as MedicationServiceClient,
} from '@digitalpharmacist/medication-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import { ResourceItem } from '../screens/refill/refill-store';
import { getText } from 'assets/localization/localization';

export interface IMedicationService {
  allergySearch(searchTerm: string): Promise<TypeaheadBaseItem[]>;
  allergyFind(id: string): Promise<ResourceItem[]>;
}

export const noKnownAllergiesValue = 'no_known_allergies' as const;

export const noKnownAllergiesOption = {
  text: getText('no-known-allergies'),
  value: noKnownAllergiesValue,
} as const;

export class MedicationService
  extends BaseApiService
  implements IMedicationService
{
  private medicationServiceClient: MedicationServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.medicationServiceClient = new MedicationServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async allergySearch(searchTerm: string): Promise<TypeaheadBaseItem[]> {
    const { data } =
      await this.medicationServiceClient.allergySearch(searchTerm);
    return data.map(allergyToResourceItem);
  }

  async allergyFind(ids: string): Promise<ResourceItem[]> {
    const { data } = await this.medicationServiceClient.allergyFind(ids);
    return data.map(allergyToResourceItem);
  }

  async getTopAllergyHints(): Promise<ResourceItem[]> {
    return (await this.medicationServiceClient.allergyFindSuggested()).data.map(
      allergyToResourceItem,
    );
  }
}

function allergyToResourceItem(allergy: AllergyDto): ResourceItem {
  return {
    text: allergy.allergy,
    value: allergy.id,
  };
}

export default new MedicationService(
  MEDICATION_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
