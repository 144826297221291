import {
  BaseApiConfiguration,
  BaseApiService,
} from '../../../../../../packages/assets/core/api';
import { AxiosRequestConfig } from 'axios';
import {
  DefaultApi as OrderClient,
  OrderDto,
} from '@digitalpharmacist/order-service-client-axios';
import { ORDER_API_BASE_URL } from '../../../common/constants';
import patientService from '../../../api/patient-service';
import { MinPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios/api';
import { LocationPatientRecordDto } from '@digitalpharmacist/patient-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../../../common/axios-default-request-config';

export type PatientOrder = {
  order: OrderDto;
  patient?: MinPatientRecordDto;
};

export interface IOrderService {
  getOrdersByPatients(
    location_id: string,
    patient_ids: string[],
  ): Promise<OrderDto[]>;

  getPatientOrders(
    location_id: string,
    patient_record_id: string,
    user_id: string,
  ): Promise<PatientOrder[]>;
}

export class OrderService extends BaseApiService implements IOrderService {
  private readonly orderClient: OrderClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.orderClient = new OrderClient(undefined, baseUrl, this.axiosInstance);
  }

  async getOrdersByPatients(
    location_id: string,
    patient_ids: string[],
  ): Promise<OrderDto[]> {
    const response = await this.orderClient.orderFindByPatientIds(
      location_id,
      patient_ids,
    );
    return response.data;
  }

  async getPatientOrders(
    location_id: string,
    patient_record_id: string,
    user_id: string,
  ): Promise<PatientOrder[]> {
    const patientRecord =
      await patientService.findPatientRecord(patient_record_id);

    const locationPatientRecordMyself =
      await patientService.findLocationPatientRecordByPR(
        location_id,
        patientRecord,
      );

    const selectedDependentIds = [];
    const locationPatientRecords: LocationPatientRecordDto[] = [];
    if (locationPatientRecordMyself) {
      selectedDependentIds.push(locationPatientRecordMyself.id);
      locationPatientRecords.push(locationPatientRecordMyself);
    }

    const patientsUnderCare =
      await patientService.recordUnderCareFindByPatientUser(user_id);

    for (const patientUnderCare of patientsUnderCare) {
      const locationPatientRecordsForPreferredLocation =
        patientUnderCare.location_patient_records.filter(
          (re) => re.location_id === location_id,
        );

      if (locationPatientRecordsForPreferredLocation.length > 0) {
        selectedDependentIds.push(
          locationPatientRecordsForPreferredLocation[0].id,
        );

        const selectedLocationPatientRecord =
          await patientService.findLocationPatientRecord(
            location_id,
            locationPatientRecordsForPreferredLocation[0].id,
          );
        if (selectedLocationPatientRecord) {
          locationPatientRecords.push(selectedLocationPatientRecord);
        }
      }
    }

    if (!selectedDependentIds.length) return [];

    const allOrders = await this.getOrdersByPatients(
      location_id,
      selectedDependentIds,
    );

    const patientOrders: PatientOrder[] = [];
    for (const order of allOrders) {
      patientOrders.push({
        order: order,
        patient: locationPatientRecords.filter(
          (lpr) => lpr.id === order.patient_id,
        )[0],
      });
    }

    return patientOrders;
  }
}

export default new OrderService(
  ORDER_API_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
