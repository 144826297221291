import { Text, View } from 'react-native';
import { useWindowDimensions } from 'react-native';
import RenderHtml from 'react-native-render-html';
import { makeStyles } from 'assets/theme';
import { tagStyles, classesStyles } from '../../utils';
import React from 'react';

export const EducationInfoSheet = ({ medication }: any) => {
  const { width } = useWindowDimensions();
  const educationInfo = { ...medication.patient_educationsheet };
  const styles = useStyles();

  return (
    medication &&
    medication.patient_educationsheet && (
      <View style={{ paddingBottom: 30 }}>
        <Text style={styles.titleStyle}>
          {medication.patient_educationsheet.title}
        </Text>
        {medication &&
          medication.patient_educationsheet &&
          educationInfo.sections.map((sheet: any) => {
            return (
              <View key={sheet.title}>
                <Text style={styles.sectionTitle}>{sheet.title}</Text>
                <RenderHtml
                  classesStyles={classesStyles}
                  tagsStyles={tagStyles}
                  contentWidth={width}
                  source={{ html: `${sheet.content}` }}
                />
              </View>
            );
          })}
        <Text style={styles.copyright}>{educationInfo.copyright}</Text>
      </View>
    )
  );
};

const useStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: 24,
    fontWeight: '500',
    marginBottom: theme.getSpacing(1),
  },
  copyright: {
    fontSize: 12,
    fontWeight: '500',
    paddingTop: theme.getSpacing(2),
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: '600',
  },
}));
