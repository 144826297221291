import {
  PharmacyLocationDto,
  TimeRangeDto,
  PharmacyDto,
  PaginatedLocationResponseDto,
} from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import pharmacyService from '../api/pharmacy-service';
import { PHARMACY_ID, PHARMACY_NAME } from '../common/constants';
import { DeepLinkingConfig } from 'assets/core/deep-linking';

export interface AppState {
  pharmacyId: string;
  pharmacyName: string;
  pharmacy: PharmacyDto;
  deepLinkingConfig?: DeepLinkingConfig;
  stores: PharmacyLocationDto[];
  getStores: () => Promise<void>;
  locationStoreHours: TimeRangeDto[];
  locationPharmacyHours: TimeRangeDto[];
  locationLanguages: string[];
  locationServices: string[];
  isMobileWeb?: boolean;
}

export const useAppStateStore = create<AppState>((set) => ({
  pharmacyId: PHARMACY_ID,
  pharmacyName: PHARMACY_NAME,
  pharmacy: {} as PharmacyDto,
  stores: [],
  getStores: async () => {
    const response = await pharmacyService.findLocationsByPharmacyId();
    set({ stores: response.locations });
  },
  locationStoreHours: [],
  locationPharmacyHours: [],
  locationLanguages: [],
  locationServices: [],
}));
