import { FunctionComponent, useState, useCallback } from 'react';
import { View } from 'react-native';
import { v4 } from 'uuid';
import {
  GiftedChat,
  GiftedChatProps,
  IMessage,
  Actions,
  ActionsProps,
  BubbleProps,
  InputToolbar,
  Composer,
} from 'react-native-gifted-chat';
import { MessageSendIcon, MicIcon, PlusSquareIcon } from 'assets/icons';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { notImplementedAlert } from 'assets/utils/alert';
import {
  DEFAULT_DATE_TIME_FORMAT,
  DEFAULT_TIME_FORMAT,
  formatDateTime,
  timeAugmenter,
} from '../../common/datetime-utils';
import { IconButton } from 'assets/components/icon-button';
import { getText } from 'assets/localization/localization';
import { useSockets } from './useSockets';
import WritingBar from '../../../../pharmacy/modules/screens/messages/components/WritingBar';
import { MESSAGE_LIMIT } from '../../../../pharmacy/modules/screens/messages/data';

export const ChatBox: FunctionComponent<ChatBoxProps> = (props) => {
  const [text, setText] = useState<string>('');
  const styles = useStyles();
  const theme = useTheme();
  const minuteThreshold = 5;

  const { typingMember = null, onType } = useSockets();

  const TimeStampComponent = (props: {
    args: Readonly<BubbleProps<IMessage>>;
  }) => {
    if (
      props.args.currentMessage &&
      (!props.args.nextMessage?.text ||
        timeAugmenter(props.args.nextMessage.createdAt, 'm', -minuteThreshold) >
          props.args.currentMessage.createdAt)
    ) {
      return (
        <Text style={styles.subText}>
          Sent{' '}
          {formatDateTime(
            props.args.currentMessage.createdAt.toLocaleString(),
            DEFAULT_DATE_TIME_FORMAT,
            DEFAULT_TIME_FORMAT,
          )}
        </Text>
      );
    } else {
      return null;
    }
  };

  const NameComponent = (props: { args: Readonly<BubbleProps<IMessage>> }) => {
    if (
      props.args.currentMessage &&
      (!props.args.previousMessage?.text ||
        timeAugmenter(
          props.args.previousMessage.createdAt,
          'm',
          minuteThreshold,
        ) < props.args.currentMessage.createdAt)
    ) {
      return (
        <Text style={styles.subText}>
          {props.args.currentMessage.user.name}
        </Text>
      );
    } else {
      return null;
    }
  };

  const renderBubble = useCallback(
    (args: Readonly<BubbleProps<IMessage>>) => {
      const isLeft = args.position === 'left';
      const isOwner = args.user?._id === args.currentMessage?.user._id;
      return (
        <View
          style={[
            { flex: 1, alignItems: 'flex-end' },
            isLeft && { alignItems: 'flex-start' },
          ]}
        >
          <NameComponent args={args} />
          <View
            style={[
              styles.bubbleContainer,
              isLeft && { marginLeft: 0, marginRight: 60 },
              isOwner && { backgroundColor: theme.palette.primary[200] },
            ]}
          >
            <Text style={[styles.bubbleText]}>{args.currentMessage?.text}</Text>
          </View>
          <TimeStampComponent args={args} />
        </View>
      );
    },
    [props.messages],
  );

  const renderActions = useCallback((props: Readonly<ActionsProps>) => {
    return (
      <>
        <Actions
          {...props}
          options={{
            ['Send Attachment']: notImplementedAlert,
          }}
          containerStyle={styles.actions}
          icon={() => (
            <PlusSquareIcon size={28} color={theme.palette.gray[500]} />
          )}
        />
      </>
    );
  }, []);

  const renderComposer = () => {
    return (
      <View style={styles.composerContainer}>
        <Composer
          placeholder={`${getText('secure-message')}...`}
          text={text}
          onTextChanged={onInputTextChanged}
          keyboardAppearance="default"
          textInputStyle={styles.composerStyle}
          textInputProps={{ maxLength: MESSAGE_LIMIT }}
        />

        {text ? (
          <IconButton
            icon={MessageSendIcon}
            logger={{ id: 'send-message' }}
            style={styles.composerIcons}
            onPress={handleMessageSend}
          />
        ) : (
          <IconButton
            icon={MicIcon}
            logger={{ id: 'mic-press' }}
            style={styles.composerIcons}
            onPress={notImplementedAlert}
          />
        )}
      </View>
    );
  };

  const handleMessageSend = useCallback(() => {
    const user = props.user;
    const textToSend = text.trim();
    if (text && props.onSend && user) {
      const newMessage = {
        _id:
          (props.messageIdGenerator && props.messageIdGenerator()) ??
          `${user._id} ${textToSend}`,
        text: textToSend,
        user: user,
        createdAt: new Date(),
      };
      props.onSend([newMessage]);

      setText('');
    }
  }, [text]);

  const renderInputToolbar = () => {
    return (
      <InputToolbar
        renderActions={renderActions}
        renderComposer={renderComposer}
        containerStyle={styles.inputToolbar}
      />
    );
  };

  const onInputTextChanged = useCallback(
    (text: string) => {
      onType(props.conversationId);
      setText(text);
    },
    [setText, onType],
  );

  return (
    <>
      <GiftedChat
        {...props}
        messages={props.messages}
        text={text}
        renderBubble={renderBubble} // *MESSAGES NOT INCLUDING AVATARS*
        renderAvatar={() => null}
        showAvatarForEveryMessage={true}
        renderInputToolbar={renderInputToolbar}
        onInputTextChanged={onInputTextChanged} // *MIGHT BE NEEDED TO UPDATE STATUS "IS TYPING" (IN LOOP UPDATE TIMER NUMBER FOR THIS VALUE)*
        renderFooter={() => (
          <WritingBar
            typingMember={typingMember}
            conversationId={props.conversationId}
          />
        )}
      />
    </>
  );
};

export interface ChatBoxProps extends GiftedChatProps {
  conversationId: string;
}

ChatBox.defaultProps = {
  alwaysShowSend: true,
  showUserAvatar: false,
  scrollToBottom: true,
  messageIdGenerator: () => v4(),
  // isTyping: true,
};

const useStyles = makeStyles((theme) => ({
  bubbleContainer: {
    marginLeft: 60,
    backgroundColor: theme.palette.gray[200],
    color: theme.palette.gray[700],
    padding: 10,
    borderRadius: 8,
    maxWidth: '90%',
  },
  bubbleText: {
    ...theme.fonts.regular,
    fontSize: 14,
    overflowWrap: 'break-word',
  },
  subText: {
    color: theme.palette.gray[500],
    fontSize: 11,
    marginTop: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(0.5),
  },

  composerStyle: {
    color: theme.palette.gray[500],
    width: '100%',
    flex: 1,
    paddingTop: theme.getSpacing(1),
  },

  composerContainer: {
    height: 40,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.palette.gray[400],
    marginLeft: theme.getSpacing(2),
    marginBottom: theme.getSpacing(0.5),
    marginRight: theme.getSpacing(1),
    alignItems: 'center',
  },

  inputToolbar: {
    borderTopWidth: 0,
  },

  composerIcons: {
    marginBottom: 4,
    marginRight: 4,
  },

  sendIcon: {
    marginBottom: theme.getSpacing(2),
  },

  actions: {
    marginBottom: 12,
  },
}));
