import {
  AppointmentTypeDto,
  BookingPageDto,
} from '@digitalpharmacist/appointment-service-client-axios';
import { PharmacyLocationDto } from '@digitalpharmacist/pharmacy-service-client-axios';
import create from 'zustand';
import { ApiError, AsyncStatus } from '../../store/types';

export const useAppointmentsState = create<AppointmentsState>(() => ({
  status: 'idle',
  routeLocationAppointmentTypesStatus: 'idle',
  refresh: false,
  offsetUpcoming: 0,
  offsetPast: 0,
  limit: 5,
  routeLocationAppointmentTypes: [],
}));

interface AppointmentsState {
  status: AsyncStatus;
  routeLocationAppointmentTypesStatus: AsyncStatus;
  upcomingAppointments?: BookingPageDto;
  pastAppointments?: BookingPageDto;
  offsetUpcoming: number;
  offsetPast: number;
  limit: number;
  error?: ApiError;
  location?: PharmacyLocationDto;
  refresh: boolean;
  appointmentsLocationId?: string;
  routeLocationAppointmentTypes: AppointmentTypeDto[];
}
