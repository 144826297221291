import React, { FunctionComponent, useEffect } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { NavigationProp, ParamListBase } from '@react-navigation/native';
import { CheckboxInput, CheckboxInputMode } from 'assets/components/checkbox';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { Text } from 'assets/components/text';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { useUserState } from '../../../store/user-store';
import {
  disableNextButton,
  enableNextButton,
  getPatientRecord,
  getPatientsUnderCare,
  setSelectedPatient,
} from './book-appointment-actions';
import { useBookAppointmentState } from './book-appointment-store';
import {
  PatientRecordDto,
  RecordUnderCareDto,
} from '@digitalpharmacist/patient-service-client-axios';

export const BookAppointmentPatientRecord: FunctionComponent<
  BookAppointmentPatientRecordProps
> = ({ navigation }) => {
  const { user } = useUserState();
  const {
    patientRecord,
    appointmentType,
    patientsUnderCare,
    patientsUnderCareRequests,
    patientsUnderCareStatus,
    selectedPatient,
    isReschedule,
  } = useBookAppointmentState();
  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    if (!isReschedule) {
      setSelectedPatient(undefined);
    }
    if (user) {
      user.patientRecordId && void getPatientRecord(user.patientRecordId);
      !patientsUnderCare.length && void getPatientsUnderCare(user.id);
    }
  }, []);

  useEffect(() => {
    if (
      (patientRecord &&
        !patientsUnderCare.length &&
        patientsUnderCareStatus !== 'loading') ||
      (patientsUnderCare.length && selectedPatient && patientRecord)
    ) {
      setSelectedPatient(selectedPatient ? selectedPatient : patientRecord);
      enableNextButton();
    } else {
      setSelectedPatient(undefined);
    }
  }, [patientRecord, patientsUnderCare]);

  const handleCheckboxPress = (
    checked: boolean,
    record: PatientRecordDto | RecordUnderCareDto,
  ) => {
    setSelectedPatient(record);

    if (checked) {
      enableNextButton();
    } else {
      disableNextButton();
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{appointmentType?.title}</Text>
      <View style={styles.divider} />
      {!patientRecord || patientsUnderCareStatus === 'loading' ? (
        <LoadingIndicator />
      ) : (
        <View style={styles.patientSelectorWrapper}>
          <Text style={{ marginBottom: theme.getSpacing(1) }}>
            {getText('select-person')}
          </Text>
          <View style={styles.checkboxWrapper}>
            <CheckboxInput
              checked={selectedPatient?.id === patientRecord.id}
              onPress={(checked) => handleCheckboxPress(checked, patientRecord)}
              mode={CheckboxInputMode.OUTLINE}
            >
              <Text style={styles.checkboxLabel}>{getText('myself')}</Text>
            </CheckboxInput>
          </View>
          {patientsUnderCare.map((patientUnderCare) => (
            <View style={styles.checkboxWrapper} key={patientUnderCare.id}>
              <CheckboxInput
                checked={
                  selectedPatient?.id ===
                    patientUnderCare.record_under_care.id ||
                  selectedPatient?.id === patientUnderCare.id
                }
                onPress={(checked) =>
                  handleCheckboxPress(checked, patientUnderCare)
                }
                mode={CheckboxInputMode.OUTLINE}
              >
                <Text style={styles.checkboxLabel}>
                  {patientUnderCare.record_under_care.first_name}{' '}
                  {patientUnderCare.record_under_care.last_name}
                </Text>
              </CheckboxInput>
            </View>
          ))}
          {patientsUnderCareRequests.length ? (
            <>
              <Text style={styles.pendingRequestsTitle}>
                {getText('pending-requests')}
              </Text>
              {patientsUnderCareRequests.map((patientUnderCare) => (
                <View style={styles.checkboxWrapper} key={patientUnderCare.id}>
                  <CheckboxInput mode={CheckboxInputMode.OUTLINE} disabled>
                    <Text
                      style={[
                        styles.checkboxLabel,
                        styles.checkboxLabelDisabled,
                      ]}
                    >
                      {patientUnderCare.first_name} {patientUnderCare.last_name}
                    </Text>
                  </CheckboxInput>
                </View>
              ))}
            </>
          ) : null}
        </View>
      )}
      <TouchableOpacity
        onPress={() =>
          navigation.navigate('people-under-care', {
            screen: 'add-patient-under-care-info',
          })
        }
      >
        <Text style={styles.link}>{getText('add-new-patient')}</Text>
      </TouchableOpacity>
      <Text style={{ color: theme.palette.gray[500] }}>
        {getText('after-adding-patient')}
      </Text>
    </View>
  );
};

export interface BookAppointmentPatientRecordProps {
  navigation: NavigationProp<ParamListBase>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    color: theme.palette.gray[700],
    textAlign: 'center',
  },
  divider: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(3),
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  checkboxWrapper: {
    marginTop: theme.getSpacing(1),
  },
  checkboxLabel: {
    marginLeft: theme.getSpacing(1),
    color: theme.palette.gray[700],
  },
  checkboxLabelDisabled: {
    color: theme.palette.gray[500],
  },
  patientSelectorWrapper: {
    marginTop: theme.getSpacing(1),
  },
  pendingRequestsTitle: {
    marginTop: theme.getSpacing(2),
  },
  link: {
    color: theme.palette.primary[600],
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
  },
}));
