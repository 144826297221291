import { AuthStackNavigationProp } from '../../navigation/AuthNavigation';
import { useResetPasswordState } from './reset-password-store';
import { getText } from 'assets/localization/localization';
import { useAppStateStore } from '../../store/app-store';
import usersService from '../../api/users-service';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { StorageKeys } from '../../../enums/storage-keys';

export const sendResetLink = async (
  values: ResetPasswordForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  const userResetPassword = { ...values, pharmacyId: pharmacyId };
  try {
    const response = await usersService.resetPassword(userResetPassword);
    await AsyncStorage.setItem(
      StorageKeys.ResetPasswordLinkId,
      response.resetPasswordLinkId!,
    );
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    if (e === 'The requested resource was not found') {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const resendResetLink = async (
  email: string,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  const userResetPassword = { email: email, pharmacyId: pharmacyId };
  try {
    const response = await usersService.resetPassword(userResetPassword);
    await AsyncStorage.setItem(
      StorageKeys.ResetPasswordLinkId,
      response.resetPasswordLinkId!,
    );
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('reset-link-sent');
  } catch (e) {
    if (e === 'The requested resource was not found') {
      navigation.navigate('reset-link-sent');
    }
    useResetPasswordState.setState({
      error: { message: getText('unable-to-send-reset-link') },
      status: 'error',
    });
  }
};

export const resetPassword = async (
  values: ResetPasswordForm,
  navigation: AuthStackNavigationProp,
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  const resetPasswordLinkId =
    (await AsyncStorage.getItem(StorageKeys.ResetPasswordLinkId)) || '';
  useResetPasswordState.setState({ error: undefined, status: 'loading' });
  const userResetPassword = {
    ...values,
    pharmacyId: pharmacyId,
    resetPasswordLinkId: resetPasswordLinkId,
  };
  try {
    await usersService.confirmPassword(userResetPassword);
    useResetPasswordState.setState({ status: 'success' });
    navigation.navigate('reset-successful');
  } catch (e) {
    navigation.navigate('reset-unsuccessful');
  }
};

export enum SendOptions {
  email = 'email',
  sms = 'sms',
}

export interface ResetPasswordForm {
  email: string;
  password: string;
  passwordConfirm: string;
  confirmationCode: string;
  sendResetLinkMethod: SendOptions;
}
