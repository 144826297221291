import { Form, ScreenContainer } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text/Text';
import { TextField } from 'assets/components/text-field';
import { Barcode } from '../../../camera/barcode/Barcode';
import { useForm } from 'react-hook-form';
import { Button } from 'assets/components/button';
import { Divider } from 'react-native-paper';
import { IconButton } from 'assets/components/icon-button';
import { TrashWithStripesIcon } from 'assets/icons';
import { FindPrescriptionFlowTestIDs } from '../FindPrescriptionFlowTestIDs';
import { getText } from 'assets/localization/localization';
import refillService from '../../refill/refill-service';
import { useAddPrescriptionStore } from '../add-prescription-store';
import { useUserState } from '../../../store/user-store';
import {
  IngressMethod,
  IntegrationType,
  RefillStatus,
} from '@digitalpharmacist/prescription-service-client-axios';
import moment from 'moment/moment';
import { useAppStateStore } from '../../../store/app-store';
import { StackHeaderProps } from '@react-navigation/stack';
import {
  ensureLocationPatientRecordExist,
  refreshPrescriptions,
} from '../add-prescription-actions';

export const ManuallyAddPrescription: FunctionComponent<
  PropsWithChildren<ManuallyAddPrescriptionProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const { pharmacyId } = useAppStateStore();
  const { locationPatientRecordId, originated, prescriptions } =
    useAddPrescriptionStore();

  const methods = useForm<PrescriptionType>({
    defaultValues: {
      rxNumber: '',
      drugName: '',
    },
  });

  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [drugsArray, setDrugsArray] = useState<PrescriptionType[]>([]);
  const [errors, setErrors] = useState<string[]>([]);

  const handleScan = (value: string) => {
    methods.setValue('rxNumber', value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  const handlePrescription = () => {
    const newPrescription = {
      drugName: methods.getValues().drugName,
      rxNumber: methods.getValues().rxNumber,
    };
    setDrugsArray((current) => [...current, newPrescription]);
    methods.reset();
  };

  const handleDelete = (deleteDrug: PrescriptionType) => {
    setDrugsArray(
      drugsArray.filter((drug) => drug.rxNumber !== deleteDrug.rxNumber),
    );
  };

  const handleOnPressDone = async () => {
    const locationId = user?.preferredPharmacyLocationId;

    if (!locationId) return alert('Location missing');
    if (!user.patientRecordId) return alert('Patient Record missing');

    let currentLPRId = locationPatientRecordId;

    if (!currentLPRId) {
      currentLPRId = await ensureLocationPatientRecordExist(
        locationId,
        user.patientRecordId,
      );
    }

    const errorList: string[] = [];
    for (const newDrug of drugsArray) {
      try {
        await refillService.createPatientPrescriptions(
          locationId,
          currentLPRId,
          {
            drug_name: newDrug.drugName,
            rx_number: newDrug.rxNumber,
            pharmacy_id: pharmacyId,
            location_id: locationId,
            patient_id: currentLPRId,
            integration_type: IntegrationType.Manual,
            ingress_method: IngressMethod.Web,
            refill_status: RefillStatus.Fillable,
          },
        );
      } catch (error) {
        if (error === 'Bad Request') {
          errorList.push(
            `Rx ${newDrug.rxNumber} - ${newDrug.drugName} already in your list`,
          );
        } else {
          errorList.push(
            `Internal Server error on saving Rx ${newDrug.rxNumber} - ${newDrug.drugName}`,
          );
        }
      }
    }

    setErrors(errorList);

    if (errorList.length) return;

    if (originated === 'medication') {
      await refreshPrescriptions();
    }

    handleSkip();
  };

  const setFindMedsScreenAsSeen = () => {
    void updateUser({ hasSeenFindMedsScreen: true });
  };

  const handleSkip = () => {
    if (originated === 'medication') {
      if (navigation.canGoBack()) {
        navigation.goBack();
      } else {
        navigation.navigate('app', { screen: 'medications' });
      }
    }
    if (originated === 'onboarding') {
      setFindMedsScreenAsSeen();
      navigation.navigate('app', { screen: 'home' });
    } else {
      navigation.navigate('app', { screen: 'medications' });
    }
  };

  const [isNumericRxNumber, setIsNumericRxNumber] = useState('');

  function isNumber(str: any) {
    if (str?.trim() === '') {
      return false;
    }
    return !isNaN(str);
  }

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>
        {getText('adding-prescriptions-for')}
        {user?.firstName} {user?.lastName}
      </Text>
      <View>
        <Divider />
      </View>
      <Text style={styles.textTitleSmall}>{getText('prescription-info')}*</Text>
      <Form methods={methods}>
        <Form.Row>
          <Form.Column>
            <TextField
              type="barcode"
              label={getText('rx-number')}
              name="rxNumber"
              handleOpenScanner={handleOpenScanner}
              onSubmit={methods.handleSubmit(handlePrescription)}
              rules={{
                required: !isNumericRxNumber
                  ? getText('rx-number-must-be-numeric')
                  : getText('rx-number-is-required'),
                validate: {
                  value: (value: string) => {
                    let isRxDuplicated = prescriptions.some(
                      (prx) => prx.rx_number === value,
                    );
                    isRxDuplicated =
                      isRxDuplicated ||
                      drugsArray.some((drug) => drug.rxNumber === value);
                    setIsNumericRxNumber(value);
                    return isRxDuplicated
                      ? getText('rx-number-is-duplicated')
                      : !isNumber(value)
                      ? getText('rx-number-must-be-numeric')
                      : true;
                  },
                },
              }}
              testID={FindPrescriptionFlowTestIDs.rxNumberTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              type="text"
              label={getText('drug-name')}
              name="drugName"
              rules={{
                required: getText('drug-name-is-required'),
              }}
              onSubmit={methods.handleSubmit(handlePrescription)}
              testID={FindPrescriptionFlowTestIDs.drugNameTextField}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={styles.addPrescriptionButton}>
            <Form.Actions>
              <Button
                hierarchy="secondary-gray"
                onPress={methods.handleSubmit(handlePrescription)}
                logger={{ id: 'add-prescription' }}
                testID={FindPrescriptionFlowTestIDs.addPrescriptionButton}
              >
                {getText('add-to-list')}
              </Button>
            </Form.Actions>
          </Form.Column>
        </Form.Row>
        <Form.Alert
          title={errors.join('\n')}
          intent="error"
          visible={!!errors.length}
        ></Form.Alert>
      </Form>
      {drugsArray.length > 0 && (
        <>
          <Text style={styles.textTitle}>{getText('your-prescriptions')}</Text>
          <View style={{ marginBottom: theme.getSpacing(0.5) }}>
            <Divider />
          </View>
        </>
      )}
      {drugsArray.map((item, index) => (
        <View key={index}>
          <View style={styles.row}>
            <Text style={styles.textTitleCard}>{item.drugName}</Text>
            <IconButton
              icon={TrashWithStripesIcon}
              color={theme.palette.gray[500]}
              onPress={() => handleDelete(item)}
              style={{
                margin: 0,
                padding: 0,
              }}
              logger={{ id: 'deleteIcon' }}
              size={15}
              testID={FindPrescriptionFlowTestIDs.trashIconButton}
            />
          </View>
          <Text style={styles.textSubtitleCardSmall}>
            {getText('entered-prescription-manually')}
          </Text>
          <Text style={styles.textSubtitleCard}>Rx: {item.rxNumber}</Text>
          <Text style={styles.textSubtitleCard}>
            {user?.firstName} {user?.lastName}{' '}
            {moment(user?.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')}
          </Text>
          <View style={{ marginVertical: theme.getSpacing(1) }}>
            <Divider />
          </View>
        </View>
      ))}
      <View style={styles.bottomButton}>
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={FindPrescriptionFlowTestIDs.doneButton}
          logger={{ id: 'add-prescription' }}
        >
          {getText('done')}
        </Button>
        {drugsArray.length === 0 && (
          <Button
            hierarchy="tertiary"
            testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
            logger={{ id: 'add-prescription' }}
            onPress={handleSkip}
          >
            {getText('do-this-later')}
          </Button>
        )}
      </View>
      <View style={{ margin: theme.getSpacing(1) }}>
        <Barcode
          onScan={handleScan}
          isVisible={isScannerVisible}
          onClose={handleScannerClose}
        />
      </View>
    </ScreenContainer>
  );
};

export type ManuallyAddPrescriptionProps = StackHeaderProps;

interface PrescriptionType {
  drugName: string;
  rxNumber: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    color: theme.palette.gray[900],
    fontWeight: '600',
    fontSize: 16,
    lineHeight: 24,
    marginVertical: theme.getSpacing(1),
  },
  textTitleSmall: {
    color: theme.palette.gray[700],
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    marginVertical: theme.getSpacing(1),
  },
  textTitleCard: {
    color: theme.palette.gray[700],
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCard: {
    color: theme.palette.gray[700],
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCardSmall: {
    color: theme.palette.gray[500],
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 18,
  },
  addPrescriptionButton: {
    width: '50%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: theme.getSpacing(1),
  },
  row: {
    flexDirection: 'row',
    marginBottom: theme.getSpacing(1),
    fontWeight: '400',
    justifyContent: 'space-between',
  },
  bottomButton: {
    bottom: 25,
    left: 0,
    right: 0,
    position: 'absolute',
  },
}));
